// @ts-ignore
import { CheckoutPageActionsFactory } from 'checkout-page-sdk-js';
import { API_URL, SDK_AUTH_WITH_CREDENTIALS } from '../config';
// @ts-ignore
import { ChooseCheckoutCurrencyRequestObject } from 'checkout-page-sdk-js/lib/generated/actions/choose_checkout_currency';

const setup_params = {
  context: {
    hostname: API_URL,
    withCredentials: SDK_AUTH_WITH_CREDENTIALS,
  },
};
const factory = new CheckoutPageActionsFactory(setup_params);

const BackEnd = {
  getPaymentOrder: async (payment_uuid: string) => {
    const action = factory.make('GetPaymentOrderCheckoutDetailsAction');
    action.routeParams = {
      payment_order_uuid: payment_uuid,
    };
    return action.run();
  },
  cancelPaymentOrder: async (payment_uuid: string) => {
    const action = factory.make('DeletePaymentOrderAction');
    action.routeParams = {
      payment_order_uuid: payment_uuid,
    };
    return action.run();
  },
  getShowPaymentOrderStatus: async () => {
    const action = factory.make('GetShowPaymentsFunctionalityStatusAction');
    return action.run();
  },

  chooseCheckoutCurrency: async (
    payment_uuid: string,
    crypto_currency: string
  ) => {
    const action = factory.make('ChooseCheckoutCurrencyAction');
    action.routeParams = {
      payment_order_uuid: payment_uuid,
    };
    action.requestBody = ChooseCheckoutCurrencyRequestObject.fromJson({
      name: crypto_currency,
    });
    return action.run();
  },
};

export { BackEnd };
