import React, { FC } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  separator: {
    borderBottom: 'solid 1px #d7dfe5',
    margin: '20px 0px',
  },
});

const Separator: FC<{ classes: ClassNameMap }> = ({ classes }) => (
  <div className={classes.separator} />
);

export default withStyles(styles)(Separator);
