import { PaymentOrder } from '../objects/PaymentOrder';

import { PAYMENT_ORDER_STATUS } from '../constants/payment-order/';
import {
  PAYMENT_ORDER_CREATED_AT_VALIDITY_WINDOW,
  PAYMENT_ORDER_EXPIRATION_TIME_VALIDITY_WINDOW,
  PAYMENT_ORDER_EXPIRES_IN_VALIDITY_WINDOW,
} from '../config';

/**
 * This function tries to guess if the local clock is consistent with the
 * clock of the server that generated the payment.
 */
function localClockConsistentWithPayment(payment: PaymentOrder): boolean {
  if (
    payment.getStatus() !== PAYMENT_ORDER_STATUS.EXPIRED &&
    payment.expiry_date.getTime() <
      Date.now() - PAYMENT_ORDER_EXPIRATION_TIME_VALIDITY_WINDOW
  ) {
    return false;
  } else if (
    payment.created_at.getTime() >
    Date.now() + PAYMENT_ORDER_CREATED_AT_VALIDITY_WINDOW
  ) {
    return false;
  } else if (
    Math.max(
      payment.expiry_date.getTime() -
        Date.now() -
        PAYMENT_ORDER_EXPIRES_IN_VALIDITY_WINDOW,
      0
    ) /
      1000 >
      payment.expires_in ||
    Math.max(
      payment.expiry_date.getTime() -
        Date.now() +
        PAYMENT_ORDER_EXPIRES_IN_VALIDITY_WINDOW,
      0
    ) /
      1000 <
      payment.expires_in
  ) {
    return false;
  } else {
    return true;
  }
}

export { localClockConsistentWithPayment };
