export const LINKS = {
  website: 'https://chainside.net',
  faq: 'https://chainside.net/domande-frequenti',
  contact_us: 'https://chainside.net/contattaci',
  terms: 'https://chainside.net/it/termini',
  privacy: 'https://chainside.net/it/privacy',
  cookie: 'https://chainside.net/it/cookie',
  instruction_payment_btc: '../../documents/it/istruzioni_pagamento.pdf',
  instruction_payment_usdt: '../../documents/it/istruzioni_pagamento_usdt.pdf',
};
