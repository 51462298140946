function goToLink(link: string, newWindow: boolean = true): void {
  window.open(link, newWindow ? '_blank' : '_self');
}

function navigateTo(args: { link?: string; newWindow: boolean }): void {
  if (!args.link) {
    throw new Error('Missing continue URL');
  }
  window.open(args.link, args.newWindow ? '_blank' : '_self');
}

export { goToLink, navigateTo };
