import * as React from 'react';

import Translate from 'react-translate-component';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import Loader from './Loader';

type Props = {
  open: boolean;
};

class AbortProgress extends React.Component<Props> {
  render() {
    const { open } = this.props;

    return (
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Translate content="common.annulla_pagamento_progress" />
          <br />
          <Loader size={40} loading />
        </DialogTitle>
      </Dialog>
    );
  }
}

export { AbortProgress };
