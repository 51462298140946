import counterpart from 'counterpart';
import translations_it from './it';
import translations_en from './en';
import { PRODUCTION_MODE } from '../config';

const languages = {
  it: 'it',
  'it-IT': 'it-IT',
  en: 'en',
  'en-US': 'en-US',
};

counterpart.registerTranslations(languages.it, translations_it);
counterpart.registerTranslations(languages['it-IT'], translations_it);
counterpart.registerTranslations(languages.en, translations_en);
counterpart.registerTranslations(languages['en-US'], translations_en);

function initLanguage() {
  const defaultLang =
    languages[navigator.language] == null ? 'en' : navigator.language;
  counterpart.setLocale(defaultLang);
}

if (!PRODUCTION_MODE) {
  counterpart.onTranslationNotFound(function(locale, key) {
    throw new Error(`Missing translation for key ${key} for locale ${locale}.`);
  });
}

export { initLanguage };
