import { and, not, or, StateMachine } from 'redux-sigma';
import {
  CheckoutEvents,
  currencySelected,
  localError,
  LocalErrorEvent,
  localPaymentRequestDone,
  paymentAborted,
  paymentFound,
  paymentNotFound,
  paymentUpdated,
  SelectCurrencyEvent,
  SocketEvents,
  SocketPaymentUpdatedEvent,
  timerExpired,
} from '../events';
import { AnyAction } from '@reduxjs/toolkit';
import { PAYMENT_ORDER_STATUS } from '../../../constants/payment-order';
import { call, delay, put, SagaGenerator } from 'typed-redux-saga';
import { localClockConsistentWithPayment } from '../../../utils';
import { PaymentOrder } from '../../../objects/PaymentOrder';
import { BackEnd } from '../../../api/backEnd';
import { PaymentOrderObjectWithBusinessInfo } from '../../types/types';
import { PaymentOrderNotFoundError } from 'checkout-page-sdk-js/lib/errors';
import { StateMachinesNames } from '../../constants';
import { socketService } from '../../../services/SocketService';

export enum CheckoutStates {
  LOAD_PAYMENT = 'LOAD_PAYMENT',
  CHECK_PAYMENT = 'CHECK_PAYMENT',
  SELECT_CURRENCY = 'SELECT_CURRENCY',
  WAIT_PAYMENT = 'WAIT_PAYMENT',
  WAIT_PAYMENT_PARTIAL = 'WAIT_PAYMENT_PARTIAL',
  WAIT_PAYMENT_EXPIRED_CONFIRMATION = 'WAIT_PAYMENT_EXPIRED_CONFIRMATION',
  SHOW_PAYMENT_ABORTED = 'SHOW_PAYMENT_ABORTED',
  SHOW_PAYMENT_EXPIRED = 'SHOW_PAYMENT_EXPIRED',
  SHOW_PAYMENT_COMPLETE = 'SHOW_PAYMENT_COMPLETE',
  SHOW_PAYMENT_NETWORK_DISPUTE = 'SHOW_PAYMENT_NETWORK_DISPUTE',
  SHOW_PAYMENT_CHARGEBACK = 'SHOW_PAYMENT_CHARGEBACK',
  PAYMENT_NOT_FOUND = 'PAYMENT_NOT_FOUND',
  SHOW_GENERIC_ERROR = 'SHOW_GENERIC_ERROR',
  FINAL_STATE_SUCCESS = 'REDIRECT_SUCCESS',
  FINAL_STATE_FAILURE = 'REDIRECT_FAILURE',
}

export interface CheckoutContext {
  sandboxMode: boolean;
  payment_uuid: string;
  payment?: PaymentOrder;

  business_info?: {
    business_logo: string;
    business_name: string;
    business_uuid: string;
  };
  online: boolean;
  error?: Error;
}

export class CheckoutStm extends StateMachine<
  CheckoutEvents & SocketEvents,
  CheckoutStates,
  StateMachinesNames,
  CheckoutContext
> {
  protected readonly initialState = CheckoutStates.LOAD_PAYMENT;

  readonly name = StateMachinesNames.checkout;

  protected readonly spec = {
    [CheckoutStates.LOAD_PAYMENT]: {
      onEntry: [this.scrollToTop, this.fetchPayment],
      transitions: {
        [CheckoutEvents.LOCAL_PAYMENT_REQUEST_DONE]:
          CheckoutStates.CHECK_PAYMENT,
        [CheckoutEvents.LOCAL_ERROR]: {
          command: this.storeError,
          target: CheckoutStates.SHOW_GENERIC_ERROR,
        },
        [CheckoutEvents.LOCAL_PAYMENT_NOT_FOUND]:
          CheckoutStates.PAYMENT_NOT_FOUND,
      },
    },
    [CheckoutStates.CHECK_PAYMENT]: {
      onEntry: [this.checkPayment],
      reactions: {
        [SocketEvents.SOCKET_PAYMENT_UPDATED]: this.updatePayment,
        [SocketEvents.RELOAD_PAYMENT_ORDER]: this.reloadPaymentOrder,
      },
      transitions: {
        [CheckoutEvents.LOCAL_PAYMENT_FOUND]: [
          {
            target: CheckoutStates.WAIT_PAYMENT,
            guard: and(this.paymentIsPending, this.hasCurrency),
          },
          {
            target: CheckoutStates.WAIT_PAYMENT_PARTIAL,
            guard: and(this.paymentIsPartial, this.hasCurrency),
          },
          {
            target: CheckoutStates.SELECT_CURRENCY,
            guard: and(
              or(this.paymentIsPending, this.paymentIsPartial),
              not(this.hasCurrency)
            ),
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_ABORTED,
            guard: this.paymentStatusAborted,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_EXPIRED,
            guard: this.paymentStatusExpired,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_COMPLETE,
            guard: this.paymentStatusCompleted,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_NETWORK_DISPUTE,
            guard: this.paymentStatusNetworkDispute,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_CHARGEBACK,
            guard: this.paymentStatusChargeback,
          },
        ],
        [CheckoutEvents.PAYMENT_UPDATED]: [
          {
            target: CheckoutStates.SHOW_PAYMENT_ABORTED,
            guard: this.paymentStatusAborted,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_EXPIRED,
            guard: this.paymentStatusExpired,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_COMPLETE,
            guard: this.paymentStatusCompleted,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_NETWORK_DISPUTE,
            guard: this.paymentStatusNetworkDispute,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_CHARGEBACK,
            guard: this.paymentStatusChargeback,
          },
        ],
        [CheckoutEvents.LOCAL_PAYMENT_ABORTED]:
          CheckoutStates.SHOW_PAYMENT_ABORTED,
        [CheckoutEvents.LOCAL_ERROR]: {
          command: this.storeError,
          target: CheckoutStates.SHOW_GENERIC_ERROR,
        },
      },
    },
    [CheckoutStates.SELECT_CURRENCY]: {
      onEntry: [this.startMonitoringBackend, this.startExpirationTimer],
      reactions: {
        [SocketEvents.SOCKET_PAYMENT_UPDATED]: this.updatePayment,
        [SocketEvents.RELOAD_PAYMENT_ORDER]: this.reloadPaymentOrder,
        [CheckoutEvents.USER_SELECTS_CURRENCY]: this.selectCurrency,
        [CheckoutEvents.USER_ABORTS_PAYMENT]: this.abortPayment,
      },
      transitions: {
        [CheckoutEvents.CURRENCY_SELECTED]: [
          {
            target: CheckoutStates.WAIT_PAYMENT,
            guard: this.paymentIsPending,
          },
          {
            target: CheckoutStates.WAIT_PAYMENT_PARTIAL,
            guard: this.paymentIsPartial,
          },
        ],
        [CheckoutEvents.LOCAL_PAYMENT_FOUND]: [
          {
            target: CheckoutStates.SHOW_PAYMENT_ABORTED,
            guard: this.paymentStatusAborted,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_COMPLETE,
            guard: this.paymentStatusCompleted,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_EXPIRED,
            guard: this.paymentStatusExpired,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_NETWORK_DISPUTE,
            guard: this.paymentStatusNetworkDispute,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_CHARGEBACK,
            guard: this.paymentStatusChargeback,
          },
        ],
        [CheckoutEvents.PAYMENT_UPDATED]: [
          {
            target: CheckoutStates.WAIT_PAYMENT,
            guard: and(this.paymentIsPending, this.hasCurrency),
          },
          {
            target: CheckoutStates.WAIT_PAYMENT_PARTIAL,
            guard: and(this.paymentIsPartial, this.hasCurrency),
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_ABORTED,
            guard: this.paymentStatusAborted,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_COMPLETE,
            guard: this.paymentStatusCompleted,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_EXPIRED,
            guard: this.paymentStatusExpired,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_NETWORK_DISPUTE,
            guard: this.paymentStatusNetworkDispute,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_CHARGEBACK,
            guard: this.paymentStatusChargeback,
          },
        ],
        [CheckoutEvents.LOCAL_PAYMENT_EXPIRED]:
          CheckoutStates.WAIT_PAYMENT_EXPIRED_CONFIRMATION,
        [CheckoutEvents.LOCAL_PAYMENT_ABORTED]:
          CheckoutStates.SHOW_PAYMENT_ABORTED,
        [CheckoutEvents.LOCAL_ERROR]: {
          command: this.storeError,
          target: CheckoutStates.SHOW_GENERIC_ERROR,
        },
      },
    },
    [CheckoutStates.WAIT_PAYMENT]: {
      onEntry: [this.startMonitoringBackend, this.startExpirationTimer],
      reactions: {
        [SocketEvents.SOCKET_PAYMENT_UPDATED]: this.updatePayment,
        [SocketEvents.RELOAD_PAYMENT_ORDER]: this.reloadPaymentOrder,
        [CheckoutEvents.USER_ABORTS_PAYMENT]: this.abortPayment,
      },
      transitions: {
        [CheckoutEvents.LOCAL_PAYMENT_FOUND]: [
          {
            target: CheckoutStates.SHOW_PAYMENT_ABORTED,
            guard: this.paymentStatusAborted,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_EXPIRED,
            guard: this.paymentStatusExpired,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_COMPLETE,
            guard: this.paymentStatusCompleted,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_NETWORK_DISPUTE,
            guard: this.paymentStatusNetworkDispute,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_CHARGEBACK,
            guard: this.paymentStatusChargeback,
          },
        ],
        [CheckoutEvents.PAYMENT_UPDATED]: [
          {
            target: CheckoutStates.WAIT_PAYMENT_PARTIAL,
            guard: this.paymentIsPartial,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_EXPIRED,
            guard: this.paymentStatusExpired,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_ABORTED,
            guard: this.paymentStatusAborted,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_COMPLETE,
            guard: this.paymentStatusCompleted,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_NETWORK_DISPUTE,
            guard: this.paymentStatusNetworkDispute,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_CHARGEBACK,
            guard: this.paymentStatusChargeback,
          },
        ],
        [CheckoutEvents.LOCAL_PAYMENT_EXPIRED]:
          CheckoutStates.WAIT_PAYMENT_EXPIRED_CONFIRMATION,
        [CheckoutEvents.LOCAL_PAYMENT_ABORTED]:
          CheckoutStates.SHOW_PAYMENT_ABORTED,
        [CheckoutEvents.LOCAL_ERROR]: {
          command: this.storeError,
          target: CheckoutStates.SHOW_GENERIC_ERROR,
        },
      },
    },
    [CheckoutStates.WAIT_PAYMENT_PARTIAL]: {
      onEntry: [this.startMonitoringBackend, this.startExpirationTimer],
      reactions: {
        [SocketEvents.SOCKET_PAYMENT_UPDATED]: this.updatePayment,
        [SocketEvents.RELOAD_PAYMENT_ORDER]: this.reloadPaymentOrder,
        [CheckoutEvents.USER_ABORTS_PAYMENT]: this.abortPayment,
      },
      transitions: {
        [CheckoutEvents.LOCAL_PAYMENT_FOUND]: [
          {
            target: CheckoutStates.SHOW_PAYMENT_ABORTED,
            guard: this.paymentStatusAborted,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_EXPIRED,
            guard: this.paymentStatusExpired,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_COMPLETE,
            guard: this.paymentStatusCompleted,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_NETWORK_DISPUTE,
            guard: this.paymentStatusNetworkDispute,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_CHARGEBACK,
            guard: this.paymentStatusChargeback,
          },
        ],
        [CheckoutEvents.PAYMENT_UPDATED]: [
          {
            target: CheckoutStates.WAIT_PAYMENT_PARTIAL,
            guard: this.paymentIsPartial,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_EXPIRED,
            guard: this.paymentStatusExpired,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_ABORTED,
            guard: this.paymentStatusAborted,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_COMPLETE,
            guard: this.paymentStatusCompleted,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_NETWORK_DISPUTE,
            guard: this.paymentStatusNetworkDispute,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_CHARGEBACK,
            guard: this.paymentStatusChargeback,
          },
        ],
        [CheckoutEvents.LOCAL_PAYMENT_EXPIRED]:
          CheckoutStates.WAIT_PAYMENT_EXPIRED_CONFIRMATION,
        [CheckoutEvents.LOCAL_PAYMENT_ABORTED]:
          CheckoutStates.SHOW_PAYMENT_ABORTED,
        [CheckoutEvents.LOCAL_ERROR]: {
          command: this.storeError,
          target: CheckoutStates.SHOW_GENERIC_ERROR,
        },
      },
    },
    [CheckoutStates.WAIT_PAYMENT_EXPIRED_CONFIRMATION]: {
      onEntry: this.scrollToTop,
      reactions: {
        [SocketEvents.SOCKET_PAYMENT_UPDATED]: this.updatePayment,
        [SocketEvents.RELOAD_PAYMENT_ORDER]: this.reloadPaymentOrder,
      },
      transitions: {
        [CheckoutEvents.LOCAL_ERROR]: {
          command: this.storeError,
          target: CheckoutStates.SHOW_GENERIC_ERROR,
        },
        [CheckoutEvents.LOCAL_PAYMENT_ABORTED]:
          CheckoutStates.SHOW_PAYMENT_ABORTED,
        [CheckoutEvents.LOCAL_PAYMENT_FOUND]: [
          {
            target: CheckoutStates.SHOW_PAYMENT_ABORTED,
            guard: this.paymentStatusAborted,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_EXPIRED,
            guard: this.paymentStatusExpired,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_COMPLETE,
            guard: this.paymentStatusCompleted,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_NETWORK_DISPUTE,
            guard: this.paymentStatusNetworkDispute,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_CHARGEBACK,
            guard: this.paymentStatusChargeback,
          },
        ],
        [CheckoutEvents.PAYMENT_UPDATED]: [
          {
            target: CheckoutStates.SHOW_PAYMENT_ABORTED,
            guard: this.paymentStatusAborted,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_EXPIRED,
            guard: this.paymentStatusExpired,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_COMPLETE,
            guard: this.paymentStatusCompleted,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_NETWORK_DISPUTE,
            guard: this.paymentStatusNetworkDispute,
          },
          {
            target: CheckoutStates.SHOW_PAYMENT_CHARGEBACK,
            guard: this.paymentStatusChargeback,
          },
        ],
      },
    },
    [CheckoutStates.SHOW_PAYMENT_ABORTED]: {
      onEntry: this.scrollToTop,
      transitions: {
        [CheckoutEvents.LOCAL_ERROR]: {
          command: this.storeError,
          target: CheckoutStates.SHOW_GENERIC_ERROR,
        },
        [CheckoutEvents.LOCAL_PAYMENT_ABORTED_REDIRECT]:
          CheckoutStates.FINAL_STATE_FAILURE,
      },
    },
    [CheckoutStates.SHOW_PAYMENT_EXPIRED]: {
      onEntry: this.scrollToTop,
      transitions: {
        [CheckoutEvents.LOCAL_ERROR]: {
          command: this.storeError,
          target: CheckoutStates.SHOW_GENERIC_ERROR,
        },
        [CheckoutEvents.LOCAL_PAYMENT_EXPIRED_REDIRECT]:
          CheckoutStates.FINAL_STATE_FAILURE,
      },
    },
    [CheckoutStates.SHOW_PAYMENT_COMPLETE]: {
      onEntry: this.scrollToTop,
      transitions: {
        [CheckoutEvents.LOCAL_ERROR]: {
          command: this.storeError,
          target: CheckoutStates.SHOW_GENERIC_ERROR,
        },
        [CheckoutEvents.LOCAL_PAYMENT_CONFIRMED_REDIRECT]:
          CheckoutStates.FINAL_STATE_SUCCESS,
      },
    },
    [CheckoutStates.SHOW_PAYMENT_NETWORK_DISPUTE]: {
      onEntry: this.scrollToTop,
      //final state
    },
    [CheckoutStates.SHOW_PAYMENT_CHARGEBACK]: {
      onEntry: this.scrollToTop,
      //final state
    },
    [CheckoutStates.PAYMENT_NOT_FOUND]: {
      //final state
    },
    [CheckoutStates.SHOW_GENERIC_ERROR]: {
      // final state
    },
    [CheckoutStates.FINAL_STATE_SUCCESS]: {
      onEntry: this.redirectSuccess,
      transitions: {
        [CheckoutEvents.LOCAL_ERROR]: {
          command: this.storeError,
          target: CheckoutStates.SHOW_GENERIC_ERROR,
        },
      },
    },
    [CheckoutStates.FINAL_STATE_FAILURE]: {
      onEntry: this.redirectFailure,
      transitions: {
        [CheckoutEvents.LOCAL_ERROR]: {
          command: this.storeError,
          target: CheckoutStates.SHOW_GENERIC_ERROR,
        },
      },
    },
  };

  *startSocket(): SagaGenerator<void> {
    try {
      yield* call(
        [socketService, socketService.start],
        this.context.payment!.uuid,
        this.context.business_info!.business_uuid
      );
      yield* call([socketService, socketService.connect]);
    } catch (e) {
      yield* put(localError({ error: e }));
    }
  }

  *updatePayment(event: SocketPaymentUpdatedEvent): SagaGenerator<void> {
    const newPayment = new PaymentOrder(
      event.payload.payment,
      this.context.business_info!.business_uuid
    );
    if (!this.context.payment || newPayment.isNewerThan(this.context.payment)) {
      yield* this.setContext(ctx => {
        ctx.payment = newPayment;
      });
      yield* put(paymentUpdated());
    }
  }

  *selectCurrency(event: SelectCurrencyEvent): SagaGenerator<void> {
    try {
      const payment_with_info: PaymentOrderObjectWithBusinessInfo = yield* call(
        BackEnd.chooseCheckoutCurrency,
        this.context.payment!.uuid,
        event.payload.currency
      );

      yield* this.setContext(ctx => {
        ctx.payment = new PaymentOrder(
          { ...payment_with_info, selected_currency: event.payload.currency },
          payment_with_info.business_uuid
        );
      });
      yield* put(currencySelected());
    } catch (e) {
      yield* put(localError({ error: e }));
    }
  }

  *abortPayment(): SagaGenerator<void> {
    try {
      const res = yield* call(
        BackEnd.cancelPaymentOrder,
        this.context.payment!.uuid
      );
      yield* this.setContext(ctx => {
        ctx.payment = new PaymentOrder(
          {
            ...this.context.payment!._object,
            cancel_url: res.cancel_url,
          },
          this.context.payment!.business_uuid
        );
      });

      yield* put(paymentAborted());
    } catch (e) {
      yield* put(localError({ error: e }));
    }
  }
  *startMonitoringBackend(): SagaGenerator<void> {
    while (true) {
      try {
        const res = yield* call(BackEnd.getShowPaymentOrderStatus);

        if (this.context.online !== res.up) {
          yield* this.setContext(ctx => {
            ctx.online = res.up;
          });
          if (res.up) {
            yield* this.reloadPaymentOrder();
          }
        }
      } catch (e) {
        //skip
      } finally {
        yield* put({ type: 'DELAY_DEBUG_EVENT' });
        yield* delay(5000);
      }
    }
  }

  *reloadPaymentOrder() {
    try {
      const payment_with_info: PaymentOrderObjectWithBusinessInfo = yield* call(
        BackEnd.getPaymentOrder,
        this.context.payment_uuid
      );
      const { business_uuid, ...payment } = payment_with_info;

      yield* this.setContext(ctx => {
        ctx.payment = new PaymentOrder(payment, business_uuid);
      });

      yield* put(paymentUpdated());
    } catch (err) {
      console.error(err);
      yield* put(localError({ error: err }));
    }
  }

  *fetchPayment() {
    try {
      const response: PaymentOrderObjectWithBusinessInfo = yield* call(
        BackEnd.getPaymentOrder,
        this.context.payment_uuid
      );
      const {
        logo: business_logo,
        business_name,
        business_uuid,
        ...payment
      } = response;

      yield* this.setContext(ctx => {
        ctx.payment = new PaymentOrder(payment, business_uuid);
        ctx.business_info = {
          business_logo,
          business_name,
          business_uuid,
        };
      });
      yield* put(localPaymentRequestDone());
    } catch (err) {
      if (err instanceof PaymentOrderNotFoundError) {
        yield* put(paymentNotFound());
      } else {
        yield* put(localError({ error: err }));
      }
    }
  }

  *checkPayment(): SagaGenerator<void> {
    if (
      this.context.sandboxMode ||
      localClockConsistentWithPayment(this.context.payment!)
    ) {
      yield* this.startSocket();
      yield* put(paymentFound());
    } else {
      console.error('Local clock inconsistent with payment');
      yield* put(
        localError({
          error: new Error('Local clock inconsistent with payment'),
        })
      );
    }
  }

  *startExpirationTimer(): SagaGenerator<void> {
    if (this.context.sandboxMode) {
      return;
    }
    yield* delay(this.context.payment!.getTimeLeft());

    if (this.context.online) {
      yield* put(timerExpired());
    }
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  *redirectSuccess() {
    if (!this.context.payment!.getContinueUrl()) {
      console.error('No payment available');
      yield* put(localError({ error: new Error('No payment available') }));
    }
    window.open(this.context.payment!.getContinueUrl()!, '_self');
  }

  *redirectFailure() {
    if (!this.context.payment!.getCancelUrl()) {
      console.error('No payment available');
      yield* put(localError({ error: new Error('No payment available') }));
    }
    window.open(this.context.payment!.getCancelUrl()!, '_self');
  }

  /* guards */

  paymentIsPending(action: AnyAction, ctx: CheckoutContext): boolean {
    return ctx.payment?.getStatus() === PAYMENT_ORDER_STATUS.PENDING;
  }

  paymentIsPartial(action: AnyAction, ctx: CheckoutContext): boolean {
    return ctx.payment?.getStatus() === PAYMENT_ORDER_STATUS.PARTIAL;
  }

  hasCurrency(action: AnyAction, ctx: CheckoutContext): boolean {
    return !!ctx.payment?.selected_currency;
  }

  paymentStatusAborted(action: AnyAction, ctx: CheckoutContext): boolean {
    return ctx.payment?.getStatus() === PAYMENT_ORDER_STATUS.ABORTED;
  }

  paymentStatusExpired(action: AnyAction, ctx: CheckoutContext): boolean {
    return ctx.payment?.getStatus() === PAYMENT_ORDER_STATUS.EXPIRED;
  }
  paymentStatusCompleted(action: AnyAction, ctx: CheckoutContext): boolean {
    return ctx.payment?.getStatus() === PAYMENT_ORDER_STATUS.CONFIRMED;
  }

  paymentStatusNetworkDispute(
    action: AnyAction,
    ctx: CheckoutContext
  ): boolean {
    return ctx.payment?.getStatus() === PAYMENT_ORDER_STATUS.NETWORK_DISPUTE;
  }

  paymentStatusChargeback(action: AnyAction, ctx: CheckoutContext): boolean {
    return ctx.payment?.getStatus() === PAYMENT_ORDER_STATUS.CHARGEBACK;
  }

  *storeError(evt: LocalErrorEvent): SagaGenerator<void> {
    yield* this.setContext(ctx => {
      ctx.error = evt.payload.error as Error;
    });
  }
}

export const checkoutStm = new CheckoutStm();
