import React from 'react';

import { millisToMinutesAndSeconds } from '../../utils/';

type Props = {
  milliseconds: number;
};

type State = {
  milliseconds: number;
  timer_id?: number;
};

class Countdown extends React.Component<Props, State> {
  state = {
    milliseconds: 0,
    timer_id: undefined,
  };

  componentDidMount() {
    this.setState(
      {
        milliseconds: this.props.milliseconds,
      },
      this.startTimer
    );
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.milliseconds !== this.props.milliseconds) {
      this.clearTimer();
      this.setState(
        {
          milliseconds: this.props.milliseconds,
        },
        this.startTimer
      );
    }
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  startTimer = () => {
    const { timer_id: old_timer_id } = this.state;
    if (old_timer_id) {
      clearInterval(old_timer_id);
    }
    const timer_id = window.setInterval(this.updateTimer, 1000);
    this.setState({
      timer_id: timer_id,
    });
  };

  updateTimer = () => {
    const { milliseconds } = this.state;
    const new_milliseconds = Math.max(0, milliseconds - 1000);
    this.setState({
      milliseconds: new_milliseconds,
    });
    if (milliseconds <= 0) {
      this.clearTimer();
    }
  };

  clearTimer = () => {
    const { timer_id } = this.state;
    if (timer_id) {
      clearInterval(timer_id);
      this.setState({
        timer_id: undefined,
      });
    }
  };

  render() {
    const { milliseconds } = this.state;
    return <span>{millisToMinutesAndSeconds(milliseconds)}</span>;
  }
}

export default Countdown;
