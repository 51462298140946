import React from 'react';
import { Loader, UserMessage } from '../common';
import Translate from 'react-translate-component';

type Props = {};

class Redirecting extends React.Component<Props> {
  render() {
    return (
      <div style={{ marginTop: '80px' }}>
        <UserMessage
          icon={<Loader size={40} loading />}
          title={<Translate content="messages.redirecting.titolo" />}
          message={<Translate content="messages.redirecting.messaggio" />}
        />
      </div>
    );
  }
}

export { Redirecting };
