import React, { ReactNode } from 'react';

import CopyText from 'react-copy-text';

import classnames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  fadeOut: {
    WebkitAnimationDuration: '1s',
    animationDuration: '1s',
    WebkitAnimationFillMode: 'both',
    animationFillMode: 'both',
    WebkitAnimationName: 'fadeOut',
    animationName: 'fadeOut',
  },
  '@keyframes fadeOut': {
    from: { opacity: 1 },
    to: { opacity: 0 },
  },
});

type Props = {
  textToCopy: string;
  copyItem: ReactNode;
  onCopyItem: ReactNode;
  classes: ClassNameMap;
};

type State = {
  textToCopy: string;
  copied: boolean;
  fadeOut: boolean;
};

class Copyer extends React.Component<Props, State> {
  state = {
    textToCopy: '',
    copied: false,
    fadeOut: false,
  };

  onButtonClick = () => {
    this.setState({
      textToCopy: this.props.textToCopy,
    });
  };

  onCopied = () => {
    this.setState({ copied: true }, () => {
      setTimeout(() => {
        this.setState({ fadeOut: true }, () => {
          setTimeout(() => {
            this.setState({ copied: false, textToCopy: '', fadeOut: false });
          }, 1000);
        });
      }, 1500);
    });
  };

  render() {
    const { textToCopy, copied } = this.state;
    const { onCopyItem, copyItem, classes } = this.props;

    return (
      <React.Fragment>
        {!copied && <span onClick={this.onButtonClick}>{copyItem}</span>}
        {copied && (
          <span
            className={classnames({ [classes.fadeOut]: this.state.fadeOut })}
            style={{ color: '#1a7ab6', marginLeft: '-30px', height: '20px' }}
          >
            {onCopyItem}
          </span>
        )}
        <CopyText text={textToCopy} onCopied={this.onCopied} />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Copyer);
