import { TIMER_EXPIRED_DELTA } from '../config';

/**
 * Computes the number of milliseconds until timer expiration, minus the timer
 * delta defined in the configs.
 */
function calculateTimerExpLocal(expiry_date: Date): number {
  return Math.max(expiry_date.getTime() - TIMER_EXPIRED_DELTA - Date.now(), 0);
}

export { calculateTimerExpLocal };
