import { currencyMap } from './currency_utils/currency';
import { CryptoCurrency } from '../redux/types/types';

function getLocalString(number: number, language: string): string {
  if (number === null || number === undefined) {
    return number;
  }
  return number.toLocaleString(language, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

function getCryptoLocalString(
  number: number | null | undefined,
  language: string,
  currency?: CryptoCurrency
): string {
  if (number === null || number === undefined) {
    return '';
  }
  return number.toLocaleString(language, {
    minimumFractionDigits: 0,
    maximumFractionDigits: currency
      ? currencyMap[currency].minimumFractionDigits
      : 0,
  });
}

export { getLocalString, getCryptoLocalString };
