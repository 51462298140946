import React from 'react';

import 'moment/locale/it';
import Translate from 'react-translate-component';
import counterpart from 'counterpart';

import { Col, Grid, Row } from 'react-flexbox-grid';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { FooterLogo } from './Icons';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { StyleRulesCallback } from '@material-ui/core/es';

const styles: StyleRulesCallback = theme => ({
  footerContainer: {
    paddingBottom: '80px',
    backgroundColor: theme.palette.primary.main,
    width: '100%',
  },
  logoContainer: {
    paddingTop: '34px',
    textAlign: 'left',
  },
  logoContainerMobile: {
    display: 'none',
  },
  infoContainer: {
    paddingTop: '34px',
    color: '#FFF',
    textAlign: 'left',
  },
  link: {
    fontSize: '13px',
    //@ts-ignore
    color: theme.palette.linkFooter,
    marginBottom: '10px',
    textDecoration: 'none',
    cursor: 'pointer',
    display: 'block',
  },
  headerLink: {
    color: theme.palette.common.white,
    marginBottom: '10px',
  },
  copyright: {
    height: '17px',
    fontFamily: 'Lato',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: theme.palette.common.white,
  },
  '@media (max-width: 768px)': {
    footer: {
      padding: '0px 20px 80px 20px',
    },
    headerLink: {
      marginTop: '30px',
    },
    logoContainerMobile: {
      display: 'block',
      textAlign: 'left',
      marginTop: '40px',
    },
    logoContainer: {
      display: 'none',
    },
  },
});

type Props = {
  visible: boolean;
  setLocale: Function;
  classes: ClassNameMap;
};

const Footer = (props: Props) => {
  const setLanguage = language => () => {
    counterpart.setLocale(language);
    props.setLocale(language);
  };

  const { classes, visible } = props;
  return visible ? (
    <div className={classes.footerContainer}>
      <Grid className={classes.footer}>
        <Row center="sm">
          <Col xs={12} sm={10}>
            <Row>
              <Col xs={12} md={4} lg={6} className={classes.logoContainer}>
                <FooterLogo />
                <Typography variant="caption" className={classes.copyright}>
                  Copyright &copy; 2018-
                  {new Date().getFullYear()}
                </Typography>
              </Col>

              <Col xs={12} md={8} lg={6} className={classes.infoContainer}>
                <div>
                  <Row>
                    <Col xs={12} sm={6} md={4}>
                      <Typography
                        variant="body2"
                        className={classes.headerLink}
                      >
                        <Translate content="footer.supporto.title" />
                      </Typography>
                      <Typography variant="body1">
                        <a
                          href={counterpart('links.website')}
                          className={classes.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Translate content="footer.supporto.website" />
                        </a>
                      </Typography>
                      <Typography variant="body1">
                        <a
                          href={counterpart('links.faq')}
                          className={classes.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Translate content="footer.supporto.faqs" />
                        </a>
                      </Typography>
                      <Typography variant="body1">
                        <a
                          href={counterpart('links.contact_us')}
                          className={classes.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Translate content="footer.supporto.contattaci" />
                        </a>
                      </Typography>
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                      <Typography
                        variant="body2"
                        className={classes.headerLink}
                      >
                        <Translate content="footer.condizioni.title" />
                      </Typography>
                      <Typography variant="body1">
                        <a
                          href={counterpart('links.terms')}
                          className={classes.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Translate content="footer.condizioni.termini" />
                        </a>
                      </Typography>
                      <Typography variant="body1">
                        <a
                          href={counterpart('links.privacy')}
                          className={classes.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Translate content="footer.condizioni.privacy" />
                        </a>
                      </Typography>
                      <Typography variant="body1">
                        <a
                          href={counterpart('links.cookie')}
                          className={classes.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Translate content="footer.condizioni.cookie_policy" />
                        </a>
                      </Typography>
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                      <Typography
                        variant="body2"
                        className={classes.headerLink}
                      >
                        <Translate content="footer.lingua.title" />
                      </Typography>
                      <Typography
                        variant="body1"
                        onClick={setLanguage('it')}
                        className={classes.link}
                      >
                        <Translate content="footer.lingua.italiano" />
                      </Typography>
                      <Typography
                        variant="body1"
                        onClick={setLanguage('en')}
                        className={classes.link}
                      >
                        <Translate content="footer.lingua.inglese" />
                      </Typography>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col xs={12} className={classes.logoContainerMobile}>
                <FooterLogo />
                <Typography variant="caption" className={classes.copyright}>
                  Copyright &copy; 2018-
                  {new Date().getFullYear()}
                </Typography>
              </Col>
            </Row>
          </Col>
        </Row>
      </Grid>
    </div>
  ) : null;
};

export default withStyles(styles)(Footer);
