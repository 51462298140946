export const LINKS = {
  website: 'https://chainside.net/en/home',
  faq: 'https://chainside.net/en/faq',
  contact_us: 'https://chainside.net/en/contact-us',
  terms: 'https://chainside.net/en/terms',
  privacy: 'https://chainside.net/en/privacy-policy',
  cookie: 'https://chainside.net/en/cookie-policy',
  instruction_payment_btc: '../../documents/en/payment_instructions.pdf',
  instruction_payment_usdt: '../../documents/en/payment_instructions_usdt.pdf',
};
