import * as React from 'react';
import { FC, useMemo } from 'react';

import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { currencyMap } from '../../utils';
import { CryptoCurrency } from '../../redux/types/types';
import { StyleRulesCallback } from '@material-ui/core/es';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { useCheckout } from '../../redux/checkout/hooks';

const styles: StyleRulesCallback = theme => {
  return {
    currencyButton: {
      border: '1px solid',
      // @ts-ignore
      borderColor: theme.palette.primary.bluegreydark,
      height: '96px',
      fontSize: '14px',
      fontWeight: 'bold',
      borderRadius: '4px',
      marginBottom: '40px',
    },
    inner: {
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
    },
    '@media (max-width: 576px)': {
      currencyButton: {
        marginBottom: '20px',
      },
    },
  };
};

type Props = {
  classes: ClassNameMap<string>;
  currency: CryptoCurrency;
};

const CurrencyButton: FC<Props> = ({ classes, currency }) => {
  const { userPressedSelectCurrency } = useCheckout();
  const Icon = useMemo(() => currencyMap[currency].icon, [currency]);

  return (
    <Button
      variant="flat"
      fullWidth
      color="default"
      className={classes.currencyButton}
      onClick={userPressedSelectCurrency(currency)}
      id={currency}
    >
      <div className={classes.inner}>
        <Icon width={32} height={32} />
        <Typography variant="body2">
          {currencyMap[currency] && currencyMap[currency].label}
        </Typography>
        <Typography variant="caption">
          {currencyMap[currency] && currencyMap[currency].extendedAbbreviation}
        </Typography>
      </div>
    </Button>
  );
};

const StyledCurrencyButton = withStyles(styles)(CurrencyButton);

export { StyledCurrencyButton as CurrencyButton };
