// Language: EN

const common = {
  attenzione: 'Warning',
  ordine_pagamento: 'PAYMENT ORDER:',
  contatta_il_team_ChainsidePay: 'contact the ChainsidePay team',
  ordine: 'Order',
  scarica_ricevuta_button: 'DOWNLOAD RECEIPT',
  prosegui_button: 'CONTINUE',
  apri_in_wallet: 'OPEN IN WALLET',
  mostra_qrcode: 'SHOW QRCODE',
  contatta_esercente:
    'The address is associated exclusively to the current order. Every amount received after the payment order expiration will not be taken into account. Contact directly the merchant for any problem.',
  annulla_pagamento_button: 'Abort Payment',
  annulla_pagamento_warning: 'Are you sure you want to abort the payment?',
  annulla_pagamento_progress: 'Aborting payment...',
  importo: 'AMOUNT',
  importo_mancante: 'MISSING AMOUNT',
  indirizzo: 'ADDRESS',
  attesa_pagamento: 'Waiting Payment ',
  rimanenti: 'remaining',
  transazioni_ricevute: 'RECEIVED TRANSACTIONS',
  ora: 'Time',
  transazione: 'Transaction',
  importo_crypto: 'Amount %(crypto_currency)s',
  importo_currency: 'Amount %(currency)s',
  conferma_button: 'CONFIRM',
  indietro_button: 'BACK',
  copiato: 'COPIED',
  payment_suspended: 'Payment suspended...',
  payment_temporary_suspended: 'Payment temporarily suspended',
  contatta_esercente_sandbox:
    'This is the sandbox version of the Checkout Page. Use the sandbox to test your webPOS integration.',
  apri_in_wallet_sandbox: 'Open the Sandbox Wallet',
};

export default common;
