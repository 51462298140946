import React, { ComponentType, FC, useMemo } from 'react';
import {
  CheckoutStates,
  checkoutStm,
} from '../../redux/checkout/stm/checkout.stm';
import { IS_SANDBOX_MODE, PAYMENT_ORDER_ID_URL_REGEX } from '../../config';
import { Searching } from '../01-Searching';
import { CurrencySelection } from '../13-CurrencySelection';
import { Waiting } from '../04-Waiting';
import { WaitingPartial } from '../05-WaitingPartial';
import { WaitingExpiredConfirmation } from '../06-WaitingExpiredConfirmation';
import { Aborted } from '../07-Aborted';
import { Expired } from '../08-Expired';
import { Complete } from '../09-Complete';
import { NotFound } from '../02-NotFound';
import { ErrorPage } from '../03-ErrorPage';
import { Redirecting } from '../10-Redirecting';
import { NetworkDispute } from '../11-NetworkDispute';
import { Chargeback } from '../12-Chargeback';
import { useCheckout } from '../../redux/checkout/hooks';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { defaultTheme, sandboxTheme } from '../../styles/theme';
import { Header, PageContainer } from '../common';
import { AppOfflineDialog } from '../common/AppOfflineDialog';
import { StyledComponentProps } from '@material-ui/core/es';
import { useStateMachine } from '../../redux/utils/useStateMachine';

const STATUS_COMPONENT_MAPPING: Record<
  CheckoutStates,
  ComponentType & StyledComponentProps<string>
> = {
  [CheckoutStates.LOAD_PAYMENT]: Searching,
  [CheckoutStates.CHECK_PAYMENT]: Searching,
  [CheckoutStates.SELECT_CURRENCY]: CurrencySelection,
  [CheckoutStates.WAIT_PAYMENT]: Waiting,
  [CheckoutStates.WAIT_PAYMENT_PARTIAL]: WaitingPartial,
  [CheckoutStates.WAIT_PAYMENT_EXPIRED_CONFIRMATION]: WaitingExpiredConfirmation,
  [CheckoutStates.SHOW_PAYMENT_ABORTED]: Aborted,
  [CheckoutStates.SHOW_PAYMENT_EXPIRED]: Expired,
  [CheckoutStates.SHOW_PAYMENT_COMPLETE]: Complete,
  [CheckoutStates.PAYMENT_NOT_FOUND]: NotFound,
  [CheckoutStates.SHOW_GENERIC_ERROR]: ErrorPage,
  [CheckoutStates.FINAL_STATE_SUCCESS]: Redirecting,
  [CheckoutStates.FINAL_STATE_FAILURE]: Redirecting,
  [CheckoutStates.SHOW_PAYMENT_NETWORK_DISPUTE]: NetworkDispute,
  [CheckoutStates.SHOW_PAYMENT_CHARGEBACK]: Chargeback,
};

const Checkout: FC = () => {
  const payment_uuid = useMemo(() => {
    const match = window.location.href.match(PAYMENT_ORDER_ID_URL_REGEX);
    return match && match[1];
  }, []);

  useStateMachine(checkoutStm, {
    sandboxMode: IS_SANDBOX_MODE,
    payment_uuid: payment_uuid!,
    online: true,
  });

  const {
    state,
    headerAndFooterVisible,
    online,
    offlineDialogOpen,
  } = useCheckout();

  const Component = useMemo(() => {
    return state && STATUS_COMPONENT_MAPPING[state];
  }, [state]);

  return (
    <MuiThemeProvider theme={IS_SANDBOX_MODE ? sandboxTheme : defaultTheme}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Header payment_uuid={payment_uuid} visible={headerAndFooterVisible} />
        <PageContainer main top white>
          <>{Component && <Component />}</>
        </PageContainer>
        {/*<Footer setLocale={this.setLocale} visible={headerAndFooterVisible} />*/}
      </div>
      {!online && <AppOfflineDialog open={offlineDialogOpen} />}
    </MuiThemeProvider>
  );
};

export default Checkout;
