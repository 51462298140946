import React, { FC } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress';
import { StyleRulesCallback } from '@material-ui/core/es';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

const styles: StyleRulesCallback = theme => ({
  container: {
    marginTop: '30px',
    textAlign: 'center',
    display: 'inline-block',
  },
});

type Props = CircularProgressProps & {
  classes: ClassNameMap;
  loading: boolean;
};

const Loader: FC<Props> = ({ classes, loading = false, ...otherProps }) => {
  return loading ? (
    <span className={classes.container}>
      <CircularProgress {...otherProps} color="primary" />
    </span>
  ) : null;
};

export default withStyles(styles)(Loader);
