import * as React from 'react';

import Translate from 'react-translate-component';

import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { StyleRulesCallback } from '@material-ui/core/es';

const styles: StyleRulesCallback = theme => ({
  goAheadButton: {
    borderRadius: '0',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  '@media (max-width: 576px)': {
    goAheadButton: {
      margin: '5px 0px',
    },
  },
});

type Props = {
  onGoAhead: () => void;
  classes: ClassNameMap;
  disabled?: boolean;
};

class GoAheadButton extends React.Component<Props> {
  render() {
    const { classes, onGoAhead, disabled } = this.props;

    return (
      <Button
        variant="raised"
        fullWidth
        color="primary"
        className={classes.goAheadButton}
        onClick={onGoAhead}
        disabled={disabled}
      >
        <Translate content="common.prosegui_button" />
      </Button>
    );
  }
}

const StyledGoAheadButton = withStyles(styles)(GoAheadButton);

export { StyledGoAheadButton as GoAheadButton };
