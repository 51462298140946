import { PAYMENT_ORDER_BACK_END_STATUS as BACK_END_STATUS } from './back-end';
import { PAYMENT_ORDER_FRONT_END_STATUS as FRONT_END_STATUS } from './front-end';

const PAYMENT_ORDER_STATUS_MAPPING = {
  [BACK_END_STATUS.PENDING]: FRONT_END_STATUS.PENDING,
  [BACK_END_STATUS.PARTIAL]: FRONT_END_STATUS.PARTIAL,
  [BACK_END_STATUS.MEMPOOL_UNCONFIRMED]: FRONT_END_STATUS.CONFIRMED,
  [BACK_END_STATUS.UNCONFIRMED]: FRONT_END_STATUS.CONFIRMED,
  [BACK_END_STATUS.COMPLETED]: FRONT_END_STATUS.CONFIRMED,
  [BACK_END_STATUS.ABORTED]: FRONT_END_STATUS.ABORTED,
  [BACK_END_STATUS.EXPIRED]: FRONT_END_STATUS.EXPIRED,
  [BACK_END_STATUS.POSSIBLE_CHARGEBACK]: FRONT_END_STATUS.NETWORK_DISPUTE,
  [BACK_END_STATUS.MEMPOOL_NETWORK_DISPUTE]: FRONT_END_STATUS.NETWORK_DISPUTE,
  [BACK_END_STATUS.NETWORK_DISPUTE]: FRONT_END_STATUS.NETWORK_DISPUTE,
  [BACK_END_STATUS.CHARGEBACK]: FRONT_END_STATUS.CHARGEBACK,
};

export { PAYMENT_ORDER_STATUS_MAPPING };
