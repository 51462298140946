import * as React from 'react';

import Translate from 'react-translate-component';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import counterpart from 'counterpart';

import { InformationMessageIcon } from './Icons';
import { CryptoCurrency } from '../../redux/types/types';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { StyleRulesCallback } from '@material-ui/core/es';

type Props = {
  classes: ClassNameMap;
  crypto_currency: CryptoCurrency;
};

const styles: StyleRulesCallback = theme => ({
  divStyle: {
    marginBottom: '32px',
    border: '1px solid #FBD08B',
    borderRadius: '4px',
  },
  leftBoxStyle: {
    backgroundColor: '#F5A623',
    width: '40px',
    margin: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleStyle: {
    padding: '0px 0px 0px 16px',
  },
  subtitleStyle: {
    padding: '5px 0px 0px 22px',
  },
  textColStyle: {
    padding: '16px',
  },
  coloredTitle: {
    color: '#F5A623',
    fontFamily: 'lato',
    height: '20px',
    fontWeight: 'bold',
    fontSize: '12px',
  },
  title: {
    color: '#444444',
    fontFamily: 'lato',
    height: '20px',
    fontWeight: 600,
    fontSize: '12px',
  },
  subtitle: {
    color: '#7a868d',
    fontFamily: 'lato',
    height: '16px',
    fontWeight: 600,
    fontSize: '12px',
  },
  link: {
    color: '#1A7AB6',
    fontFamily: 'lato',
    height: '16px',
    fontWeight: 600,
    fontSize: '12px',
    textDecoration: 'none',
  },
  typoStyle: {
    margin: '0px',
  },
});

class InformationMessage extends React.Component<Props> {
  render() {
    const { classes, crypto_currency } = this.props;
    const linkPdf = counterpart(
      crypto_currency === 'BTC'
        ? 'links.instruction_payment_btc'
        : 'links.instruction_payment_usdt'
    );
    const linkField = () => {
      if (linkPdf.includes('../../documents/it/istruzioni_pagamento.pdf')) {
        return (
          <a
            className={classes.link}
            href={
              require('../../documents/it/istruzioni_pagamento.pdf').default
            }
            rel="noopener noreferrer"
            target="_blank"
          >
            <Translate
              content={'messages.information_message.subtitle.row3.link'}
            />
          </a>
        );
      } else if (
        linkPdf.includes('../../documents/en/payment_instructions.pdf')
      ) {
        return (
          <a
            className={classes.link}
            href={
              require('../../documents/en/payment_instructions.pdf').default
            }
            rel="noopener noreferrer"
            target="_blank"
          >
            <Translate
              content={'messages.information_message.subtitle.row3.link'}
            />
          </a>
        );
      } else if (
        linkPdf.includes('../../documents/it/istruzioni_pagamento_usdt.pdf')
      ) {
        return (
          <a
            className={classes.link}
            href={
              require('../../documents/it/istruzioni_pagamento_usdt.pdf')
                .default
            }
            rel="noopener noreferrer"
            target="_blank"
          >
            <Translate
              content={'messages.information_message.subtitle.row3.link'}
            />
          </a>
        );
      } else if (
        linkPdf.includes('../../documents/en/payment_instructions_usdt.pdf')
      ) {
        return (
          <a
            className={classes.link}
            href={
              require('../../documents/en/payment_instructions_usdt.pdf')
                .default
            }
            rel="noopener noreferrer"
            target="_blank"
          >
            <Translate
              content={'messages.information_message.subtitle.row3.link'}
            />
          </a>
        );
      } else {
        return <></>;
      }
    };

    return (
      <div className={classes.divStyle}>
        <Grid>
          <Row>
            {/*no fluid ?*/}
            <Col className={classes.leftBoxStyle}>
              <InformationMessageIcon />
            </Col>
            <Col
              sm={10}
              md={10}
              lg={10}
              xs={10}
              className={classes.textColStyle}
            >
              <Row className={classes.titleStyle}>
                <Typography variant="body1" className={classes.typoStyle}>
                  <Translate
                    content={'messages.information_message.title_part_1'}
                    className={classes.title}
                  />
                  &nbsp;
                  <Translate
                    content={'messages.information_message.title_part_2'}
                    className={classes.coloredTitle}
                  />
                  &nbsp;
                  <Translate
                    content={'messages.information_message.title_part_3'}
                    className={classes.title}
                  />
                </Typography>
              </Row>
              <Row className={classes.subtitleStyle}>
                <Typography variant="body1" className={classes.typoStyle}>
                  <Translate
                    content={'messages.information_message.subtitle.row1.text'}
                    className={classes.subtitle}
                  />
                </Typography>
              </Row>
              <Row className={classes.subtitleStyle}>
                <Typography variant="body1" className={classes.typoStyle}>
                  <Translate
                    content={'messages.information_message.subtitle.row2.text'}
                    className={classes.subtitle}
                  />
                </Typography>
              </Row>
              <Row className={classes.subtitleStyle}>
                <Typography variant="body1" className={classes.typoStyle}>
                  <Translate
                    content={'messages.information_message.subtitle.row3.text'}
                    className={classes.subtitle}
                  />
                  &nbsp;
                  {linkField()}
                </Typography>
              </Row>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const InformationMessageDecorated = withStyles(styles)(InformationMessage);

export { InformationMessageDecorated as InformationMessage };
