import React from 'react';
import { withStyles } from '@material-ui/core';
import { Col, Row } from 'react-flexbox-grid';
import { DownloadButton } from './DownloadButton';
import { GoAheadButton } from './GoAheadButton';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  noPadding: {
    padding: '0',
  },
  '@media (max-width: 576px)': {
    container: {
      marginLeft: '0px',
      marginRight: '0px',
    },
  },
});

type Props = {
  canDownload: Boolean;
  onDownload: () => void;
  onGoAhead: () => void;
  classes: ClassNameMap;
};

class DownloadAndGoAheadButtons extends React.Component<Props> {
  static defaultProps = {
    canDownload: true,
    onDownload: () => {},
    onGoAhead: () => {},
  };

  render() {
    const { classes, canDownload, onDownload, onGoAhead } = this.props;
    return (
      <Row center="sm" className={classes.container}>
        <Col xs={12} sm={10} md={8} className={classes.noPadding}>
          <Row center="sm">
            {canDownload && (
              <Col xs={12} sm={6}>
                <DownloadButton onDownload={onDownload} />
              </Col>
            )}
            <Col xs={12} sm={6}>
              <GoAheadButton onGoAhead={onGoAhead} />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default withStyles(styles)(DownloadAndGoAheadButtons);
