import React, { FC } from 'react';
import Translate from 'react-translate-component';
import counterpart from 'counterpart';
import { withStyles } from '@material-ui/core';
import { OrderHeader, UserMessage } from '../common';
import { ChargebackIcon } from '../common/Icons';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { useCheckout } from '../../redux/checkout/hooks';

const styles = theme => ({
  icon: {
    fontSize: '32px',
    color: theme.palette.grey['4'],
  },
  title: {
    color: theme.palette.primary.main,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
});

type Props = {
  classes: ClassNameMap;
};

const Chargeback: FC<Props> = ({ classes }) => {
  const { payment, businessInfo } = useCheckout();

  return (
    <div>
      <OrderHeader
        businessName={businessInfo?.business_name}
        businessLogo={businessInfo?.business_logo}
        payment={payment}
      />
      <UserMessage
        icon={<ChargebackIcon width={32} height={32} />}
        title={
          <Translate
            content="messages.payment_chargeback.title"
            className={classes.title}
          />
        }
        message={
          <React.Fragment>
            <Translate content="messages.payment_chargeback.subtitle.row1.text" />
            <br />
            <Translate content="messages.payment_chargeback.subtitle.row2.text" />
            <a href={counterpart('links.contact_us')} className={classes.link}>
              <Translate content="messages.payment_chargeback.subtitle.row2.link" />
            </a>
          </React.Fragment>
        }
      />
    </div>
  );
};

const StyledChargeback = withStyles(styles)(Chargeback);

export { StyledChargeback as Chargeback };
