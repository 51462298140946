import React, { FC, useMemo } from 'react';
import Translate from 'react-translate-component';
import { Loader, OrderHeader, UserMessage } from '../common';
import { getCryptoLocalString, getLocalString } from '../../utils';
import { getCryptoAbbreviation } from '../../utils/currency_utils/currency';
import { useCheckout } from '../../redux/checkout/hooks';
import counterpart from 'counterpart';

const WaitingExpiredConfirmation: FC = () => {
  const { businessInfo, payment } = useCheckout();

  const language = useMemo(() => {
    return counterpart.getLocale();
  }, []);

  return (
    <div>
      <OrderHeader
        businessName={businessInfo?.business_name}
        businessLogo={businessInfo?.business_logo}
        payment={payment}
      />
      <UserMessage
        icon={<Loader size={40} loading />}
        title={<Translate content="messages.waiting_expired.titolo" />}
        message={
          <>
            {payment && payment.hasReceived() && (
              <div>
                <Translate
                  with={{
                    crypto: getCryptoLocalString(
                      payment!.crypto_received!.toNumber(),
                      language,
                      payment!.selected_currency!
                    ),
                    currency: getCryptoAbbreviation(
                      payment!.selected_currency!
                    ),
                    amount:
                      getLocalString(payment.received.toNumber(), language) +
                      ' ' +
                      payment.currency,
                  }}
                  content="messages.waiting_expired.received"
                />
              </div>
            )}
            <Translate content="messages.waiting_expired.sottotitolo" />
          </>
        }
        submessage={
          <Translate content="messages.waiting_expired.quelche_minuto" />
        }
      />
    </div>
  );
};

export { WaitingExpiredConfirmation };
