import createMuiTheme, {
  ThemeOptions,
} from '@material-ui/core/styles/createMuiTheme';
import { green } from '@material-ui/core/colors';
import ObjectAssignDeep from 'object-assign-deep';

const defaultThemeSpec: ThemeOptions = {
  palette: {
    primary: {
      main: '#1a7ab6',
      //@ts-ignore
      link: '#1a7ab6',
      dark: '#1a6797',
      darker: '#135985',
      light: '#7babcb',
      lighter: '#d7dfe5',
      bluegrey: '#d7dfe5',
      bluegreydark: '#b5c1c9',
      bluegreylight: '#f2f3f3',
      bg: '#f4f7f8',
      bgNavBar: '#e6edf1',
    },
    secondary: green,
    orange: {
      main: '#f5a623',
      dark: '#dc931b',
      light: '#fbd08b',
      bg: '#fcedd6',
    },
    green: {
      main: '#619823',
      dark: '#52821a',
      light: '#aac48c',
      bg: '#daecd0',
    },
    red: {
      main: '#dd4848',
      bg: '#fff5f5',
    },
    error: {
      main: '#dd4848',
      //@ts-ignore
      bg: '#fff5f5',
    },
    grey: {
      //@ts-ignore
      main: '#e0e0e0',
      dark: '#888888',
      // light: '#f0f0f0'
      light: '#c5c5c5',
      background: '#fafafa',
      midDark: '#d7dfe5',
      4: '#444444',
      7: '#777777',
    },
    badge: {
      success: '#619823',
      error: '#c62828',
      warning: '#f5a623',
      info: '#1976d2',
    },
    alert: {
      backgroundColor: '#fff6e6',
      borderColor: '#efca8e',
      border: '1px solid',
      color: '#f5a623',
    },
    infoSection: {
      backgroundColor: '#f4f7f8',
      color: '#1a7ab6',
    },
    pos: {
      web: '#f5a623',
      mobile: '#619823',
    },
    linkFooter: '#d7dfe5',
  },
  components: {
    tableRow: {
      borderRadius: '6px',
      boxShadow: '0 1px 2px 0 #d7dfe5',
      backgroundColor: '#ffffff',
      border: 'solid 1px #d7dfe5',
    },
    inputField: {
      borderRadius: '2px',
      padding: '10px 12px 11px',
      fontSize: '14px',
      color: '#444444',
      '&::placeholder': {
        fontStyle: 'italic',
        color: '#aaaaaa',
      },
      '&:disabled': {
        color: '#aaaaaa',
      },
    },
  },
  typography: {
    fontFamily: 'Lato',
    fontWeightBold: 600,
    weights: {
      thin: 500,
      normal: 600,
      bold: 800,
    },
    subheading: {
      color: '#1a7ab6',
    },
    body1: {
      fontSize: '14px',
      //@ts-ignore
      fontWeight: '600',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#7a868d',
      margin: '5px 0px',
    },
    body2: {
      fontSize: '14px',
      fontWeight: 600,
      //@ts-ignore
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#444444',
    },
    title: {
      fontSize: '20px',
      fontWeight: 600,
      //@ts-ignore
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#1a7ab6',
      //@ts-ignore
      textAlign: 'center',
    },
    caption: {
      fontSize: '14px',
      fontWeight: 600,
      //@ts-ignore
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#999999',
    },
    boxBody: {
      height: '16px',
      fontFamily: 'Montserrat',
      fontSize: '12px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'left',
      color: '#666666',
    },
    errorLabel: {
      color: '#dd4848',
      margin: '-2px 0px 5px 2px',
      lineHeight: '1em',
      fontSize: '0.75em',
    },
    link: {
      textDecoration: 'none',
      fontSize: '14px',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 4,
        fontSize: '16px',
        paddingTop: '10px',
        paddingBottom: '10px',
        boxShadow: 'none',
      },
      raised: {
        boxShadow: 'none',
      },
      raisedPrimary: {
        '&:hover': {
          backgroundColor: '#1a6797',
        },
      },
      disabled: {
        color: 'inherit',
        backgroundColor: 'inherit',
      },
      sizeLarge: {
        padding: '13px 24px',
      },
    },
    MuiInput: {
      root: {
        borderRadius: '2px',
        border: 'solid 1px #d7dfe5',
      },
      error: {
        borderColor: '#dd4848',
      },
    },
    MuiInputLabel: {
      root: {
        padding: '0px 10px',
        zIndex: 1,
      },
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: 'transparent',
        color: 'inherit',
      },
    },
  },
  shadow: {
    boxShadow: '0 1px 2px 0 #d7dfe5',
  },
  appBarShadow: '0 1px 0 0 #c3dbeb',
  icon: {
    small: {
      width: '24px',
      height: '24px',
    },
    medium: {
      width: '40px',
      height: '40px',
    },
    large: {
      width: '60px',
      height: '60px',
    },
    arrowSmall: {
      width: '11px',
      height: '20px',
    },
    arrowLarge: {
      width: '19px',
      height: '32px',
    },
    icon22: {
      width: '22px',
      height: '22px',
    },
  },
};

const sandboxThemeSpecDiff = {
  palette: {
    primary: {
      main: '#396078',
      link: '#396078',
      dark: '#2b4f67',
      light: '#9AB7CA',
      bgNavBar: '#32607D',
      bgtopBarFooter: '#2B4F67',
      navbarText: '#ffffff',
    },
  },
  typography: {
    title: {
      color: '#396078',
    },
  },
  overrides: {
    MuiButton: {
      raisedPrimary: {
        '&:hover': {
          backgroundColor: '#2b4f67',
        },
      },
    },
  },
};

const sandboxThemeSpec = ObjectAssignDeep(
  {},
  defaultThemeSpec,
  sandboxThemeSpecDiff
);

const sandboxTheme = createMuiTheme(sandboxThemeSpec);
const defaultTheme = createMuiTheme(defaultThemeSpec);

export { sandboxTheme, defaultTheme };

export default createMuiTheme();

export type CustomTheme = typeof defaultTheme;
