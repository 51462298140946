// Language: IT

const footer = {
  supporto: {
    title: 'SUPPORTO',
    website: 'ChainsidePay',
    faqs: 'Domande Frequenti',
    documentazione: 'Documentazione',
    contattaci: 'Contattaci',
  },
  condizioni: {
    title: 'CONDIZIONI',
    termini: 'Termini',
    privacy: 'Privacy',
    cookie_policy: 'Cookie Policy',
  },
  lingua: {
    title: 'LINGUA',
    italiano: 'Italiano',
    inglese: 'Inglese',
  },
};

export default footer;
