import { SOCKET_MAX_RETRIES, SOCKET_URL } from '../config';
import { CheckoutPageSocketsFactory } from 'checkout-page-sdk-js';
import { CheckoutEvents, SocketEvents } from '../redux/checkout/events';
import { PaymentOrderObject } from '../redux/types/types';
import { Store } from '@reduxjs/toolkit';

class SocketService {
  private socket?: CheckoutPageSocketsFactory.sockets.PaymentOrderSocket;
  private paymentOrderId?: string;
  private businessId?: string;
  private store!: Store;

  initialized = false;

  constructor(readonly hostName: string, readonly maxRetries?: number) {}

  init(store: Store) {
    this.initialized = true;
    this.store = store;
  }

  public start(paymentOrderId: string, businessId: string) {
    if (!this.initialized) throw new Error('SocketService not initialized');

    this.paymentOrderId = paymentOrderId;
    this.businessId = businessId;

    const factory = new CheckoutPageSocketsFactory({
      hostname: this.hostName,
      maxRetries: this.maxRetries,
      onFail: () => {
        this.store!.dispatch({
          type: CheckoutEvents.LOCAL_ERROR,
          payload: { error: 'Socket connection failed' },
        });
      },
      onReconnect: () => {
        try {
          this.socket?.unsubscribeAll();
          this.socket?.subscribe(this.paymentOrderId);
          this.store.dispatch({
            type: SocketEvents.RELOAD_PAYMENT_ORDER,
          });
        } catch (e) {
          this.store.dispatch({
            type: CheckoutEvents.LOCAL_ERROR,
            payload: { error: 'No socket or payment id found on reconnection' },
          });
        }
      },
    });

    this.socket = factory.make('PaymentOrderV2Socket');

    this.socket.on(
      'payment.updated',
      (payment: PaymentOrderObject) => {
        this.store.dispatch({
          type: SocketEvents.SOCKET_PAYMENT_UPDATED,
          payload: { payment },
        });
      },
      error => {
        this.store.dispatch({
          type: CheckoutEvents.LOCAL_ERROR,
          payload: { error },
        });
      }
    );
  }

  connect() {
    this.socket.subscribe(this.paymentOrderId);
  }

  stop() {
    this.socket.unsubscribeAll();
    this.socket.disconnect();
  }
}

export const socketService = new SocketService(SOCKET_URL, SOCKET_MAX_RETRIES);
