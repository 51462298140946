const PAYMENT_ORDER_BACK_END_STATUS = {
  PARTIAL: 'partial',
  PENDING: 'pending',
  MEMPOOL_UNCONFIRMED: 'mempool_unconfirmed',
  UNCONFIRMED: 'unconfirmed',
  COMPLETED: 'paid',
  ABORTED: 'cancelled',
  EXPIRED: 'expired',
  CHARGEBACK: 'chargeback',
  POSSIBLE_CHARGEBACK: 'possible_chargeback',
  NETWORK_DISPUTE: 'network_dispute',
  MEMPOOL_NETWORK_DISPUTE: 'mempool_network_dispute',
};

export { PAYMENT_ORDER_BACK_END_STATUS };
