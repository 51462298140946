import React, { FC, useCallback, useMemo } from 'react';
import Translate from 'react-translate-component';
import counterpart from 'counterpart';
import { Col, Row } from 'react-flexbox-grid';
import { withStyles } from '@material-ui/core';
import {
  DownloadAndGoAheadButtons,
  OrderHeader,
  UserMessage,
  WarningMessage,
} from '../common';
import { ExpiredIcon } from '../common/Icons';
import { getCryptoLocalString, getLocalString } from '../../utils';
import { goToLink } from '../../utils/navigation';
import { getCryptoAbbreviation } from '../../utils/currency_utils/currency';
import { useCheckout } from '../../redux/checkout/hooks';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  icon: {
    fontSize: '32px',
    color: theme.palette.grey['4'],
  },
  title: {
    color: theme.palette.primary.main,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
});

type Props = {
  classes: ClassNameMap;
};

const Expired: FC<Props> = ({ classes }) => {
  const { payment, businessInfo, userPressedRedirectExpired } = useCheckout();
  const onDownload = useCallback(() => {
    goToLink(payment!.getReceiptLink());
  }, [payment]);

  const language = useMemo(() => {
    return counterpart.getLocale();
  }, []);

  return (
    <div>
      <OrderHeader
        businessName={businessInfo?.business_name}
        businessLogo={businessInfo?.business_logo}
        payment={payment}
      />
      <UserMessage
        icon={<ExpiredIcon width={32} height={32} />}
        title={
          <Translate
            content="messages.payment_expired.titolo"
            className={classes.title}
          />
        }
        message={
          <React.Fragment>
            <Translate content="messages.payment_expired.parte_1" />
            <span> </span>
            <Translate content="messages.payment_expired.parte_2" />
            <br />
            <br />
            <Translate content="messages.payment_expired.parte_3" />
            <a href={counterpart('links.contact_us')} className={classes.link}>
              <Translate content="messages.payment_expired.parte_4" />
            </a>
            <Translate content="messages.payment_expired.parte_5" />
          </React.Fragment>
        }
      />
      {payment!.hasReceived() && (
        <Row center="sm">
          <Col xs={12} sm={10}>
            <WarningMessage
              title={
                <Translate content="messages.payment_expired.attenzione" />
              }
              message={
                <Translate
                  with={{
                    crypto: getCryptoLocalString(
                      payment!.crypto_received!.toNumber(),
                      language,
                      payment!.selected_currency!
                    ),
                    currency: getCryptoAbbreviation(
                      payment!.selected_currency!
                    ),
                    amount:
                      getLocalString(payment!.received.toNumber(), language) +
                      ' ' +
                      payment!.currency,
                  }}
                  content="messages.payment_expired.pagamento_parziale"
                />
              }
            />
          </Col>
        </Row>
      )}
      <DownloadAndGoAheadButtons
        canDownload={payment!.hasReceived()}
        onDownload={onDownload}
        onGoAhead={userPressedRedirectExpired}
      />
    </div>
  );
};

export default withStyles(styles)(Expired);
