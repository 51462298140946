// Language: EN

const footer = {
  supporto: {
    title: 'SUPPORT',
    website: 'ChainsidePay',
    faqs: 'Faqs',
    documentazione: 'Documentation',
    contattaci: 'Contact us',
  },
  condizioni: {
    title: 'CONDITIONS',
    termini: 'Terms',
    privacy: 'Privacy',
    cookie_policy: 'Cookie Policy',
  },
  lingua: {
    title: 'LANGUAGE',
    italiano: 'Italian',
    inglese: 'English',
  },
};

export default footer;
