import React, { FC, useCallback } from 'react';
import Translate from 'react-translate-component';
import counterpart from 'counterpart';
import { Col, Row } from 'react-flexbox-grid';
import { withStyles } from '@material-ui/core';
import { OrderHeader, UserMessage } from '../common';
import { goToLink } from '../../utils/navigation';
import { NetworkDisputeIcon } from '../common/Icons';
import { DownloadButton } from '../common/DownloadButton';
import { useCheckout } from '../../redux/checkout/hooks';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  icon: {
    fontSize: '32px',
    color: theme.palette.grey['4'],
  },
  title: {
    color: theme.palette.primary.main,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
});

type Props = {
  classes: ClassNameMap;
};

const NetworkDispute: FC<Props> = ({ classes }) => {
  const { payment, businessInfo } = useCheckout();
  const onDownload = useCallback(() => {
    goToLink(payment!.getReceiptLink());
  }, [payment]);

  return (
    <div>
      <OrderHeader
        businessName={businessInfo?.business_name}
        businessLogo={businessInfo?.business_logo}
        payment={payment}
      />
      <UserMessage
        icon={<NetworkDisputeIcon width={32} height={32} />}
        title={
          <Translate
            content="messages.payment_network_dispute.title"
            className={classes.title}
          />
        }
        message={
          <React.Fragment>
            <Translate content="messages.payment_network_dispute.subtitle.row1.text" />
            <a href={counterpart('links.faq')} className={classes.link}>
              <Translate content="messages.payment_network_dispute.subtitle.row1.link" />
            </a>
            <br />
            <Translate content="messages.payment_network_dispute.subtitle.row2.text" />
            <a href={counterpart('links.contact_us')} className={classes.link}>
              <Translate content="messages.payment_network_dispute.subtitle.row2.link" />
            </a>
          </React.Fragment>
        }
      />
      <Row center="sm">
        <Col xs={12} sm={5} md={4}>
          <DownloadButton onDownload={onDownload} />
        </Col>
      </Row>
    </div>
  );
};

const StyledNetworkDispute = withStyles(styles)(NetworkDispute);

export { StyledNetworkDispute as NetworkDispute };
