/**
 * Convert milliseconds to minutes and seconds (m:ss)
 */
function millisToMinutesAndSeconds(millis: number): string {
  const timeSeconds = Math.max(0, Math.floor(millis / 1000));
  const minutes = Math.floor(timeSeconds / 60).toString();
  let seconds = Math.floor(timeSeconds % 60).toString();
  if (Number(seconds) < 10) {
    seconds = `0${seconds}`;
  }
  return `${minutes}:${seconds}`;
}

export { millisToMinutesAndSeconds };
