import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import { unregister } from './registerServiceWorker';
import { initLanguage } from './languages/config';
import { App } from './App';

initLanguage();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<App />);

unregister();
