import COMMON from './common';
import ERRORS from './errors';
import FOOTER from './footer';
import MESSAGES from './messages';
import { LINKS } from './links';

export const lang = {
  common: COMMON,
  errors: ERRORS,
  footer: FOOTER,
  messages: MESSAGES,
  links: LINKS,
};

export default lang;
