import * as React from 'react';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { StyleRulesCallback } from '@material-ui/core/es';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { FC, PropsWithChildren } from 'react';

const styles: StyleRulesCallback = theme => ({
  container: {
    textAlign: 'left',
    width: '100%',
    flex: '1 1 auto',
  },
  paddingBottom: {
    paddingBottom: '120px',
  },
  backgroundWhite: {
    backgroundColor: theme.palette.common.white,
  },
  backgroundGrey: {
    //@ts-ignore
    backgroundColor: theme.palette.grey.background,
  },
  containerMobile: {},
  '@media (max-width: 576px)': {
    containerMobile: {
      paddingRight: '0px',
      paddingLeft: '0px',
    },
  },
});

type Props = {
  main: boolean;
  white?: boolean;
  grey?: boolean;
  top?: boolean;
  classes: ClassNameMap;
};

const PageContainer: FC<PropsWithChildren<Props>> = ({
  classes,
  grey,
  children,
  top,
  white,
  main,
}) => (
  <Grid
    className={classnames({
      [classes.container]: true,
      [classes.paddingBottom]: main,
      [classes.backgroundWhite]: white,
      [classes.backgroundGrey]: grey,
      [classes.paddingTop]: top,
    })}
  >
    <div
      className={classnames({
        container: true,
        [classes.containerMobile]: true,
      })}
    >
      <Row center="sm" style={{ textAlign: 'left' }}>
        <Col xs={12} sm={12} md={11} lg={10}>
          {children}
        </Col>
      </Row>
    </div>
  </Grid>
);

export default withStyles(styles)(PageContainer);
