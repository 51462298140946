import { useDispatch, useSelector } from 'react-redux';
import {
  selectBusinessInfo,
  selectHeaderAndFooterVisible,
  selectIsOnline,
  selectOfflineDialogIsOpen,
  selectPayment,
  selectState,
} from './selectors';
import { useCallback } from 'react';
import {
  paymentAbortedRedirect,
  paymentConfirmedRedirect,
  paymentExpiredRedirect,
  userAbortsPayment,
  userSelectsCurrency,
} from './events';

export const useCheckout = () => {
  const state = useSelector(selectState);
  const headerAndFooterVisible = useSelector(selectHeaderAndFooterVisible);
  const online = useSelector(selectIsOnline);
  const offlineDialogOpen = useSelector(selectOfflineDialogIsOpen);
  const payment = useSelector(selectPayment);
  const businessInfo = useSelector(selectBusinessInfo);

  const dispatch = useDispatch();

  const userPressedRedirectAborted = useCallback(() => {
    dispatch(paymentAbortedRedirect());
  }, [dispatch]);

  const userPressedRedirectExpired = useCallback(() => {
    dispatch(paymentExpiredRedirect());
  }, [dispatch]);

  const userPressedRedirectConfirmed = useCallback(() => {
    dispatch(paymentConfirmedRedirect());
  }, [dispatch]);

  const userPressedSelectCurrency = useCallback(
    currency => () => {
      dispatch(userSelectsCurrency({ currency }));
    },
    [dispatch]
  );

  const userPressedAbortPayment = useCallback(() => {
    dispatch(userAbortsPayment());
  }, [dispatch]);

  return {
    state,
    headerAndFooterVisible,
    online,
    offlineDialogOpen,
    payment,
    businessInfo,
    userPressedRedirectAborted,
    userPressedRedirectExpired,
    userPressedRedirectConfirmed,
    userPressedSelectCurrency,
    userPressedAbortPayment,
  };
};
