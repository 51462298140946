import * as React from 'react';
import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Translate from 'react-translate-component';
import { withStyles } from '@material-ui/core/styles';
import { AbortConfirmation } from './AbortConfirmation';
import { AbortProgress } from './AbortProgress';
import { StyleRulesCallback } from '@material-ui/core/es';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { useCheckout } from '../../redux/checkout/hooks';

const styles: StyleRulesCallback<string> = theme => ({
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  confirmButton: {
    borderRadius: '0',
    color: '#FFF',
    backgroundColor: theme.palette.error.main,
    fontWeight: 'bold',
    marginBottom: '10px',
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
  },
  backButton: {
    borderRadius: '0',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
});

interface Props {
  classes: ClassNameMap<string>;
}

enum STATUS {
  CONFIRM = 'CONFIRM',
  CANCELLING = 'CANCELLING',
}

const COMPONENT_MAPPING = {
  [STATUS.CONFIRM]: AbortConfirmation,
  [STATUS.CANCELLING]: AbortProgress,
};

const AbortLinkButton: FC<PropsWithChildren<Props>> = ({
  classes,
  children,
}) => {
  const { userPressedAbortPayment } = useCheckout();
  const [status, setStatus] = useState(STATUS.CONFIRM);
  const [modalOpen, setOpenModal] = useState(false);
  const openModal = useCallback(() => {
    setOpenModal(true);
  }, [setOpenModal]);

  const closeModal = useCallback(() => {
    setOpenModal(false);
  }, [setOpenModal]);

  const onConfirm = useCallback(() => {
    setStatus(STATUS.CANCELLING);
    userPressedAbortPayment();
    closeModal();
  }, [closeModal, userPressedAbortPayment]);

  const Component = useMemo(() => {
    return status && COMPONENT_MAPPING[status];
  }, [status]);

  return (
    <span>
      <Typography variant="body1" onClick={openModal}>
        <Translate
          content="common.annulla_pagamento_button"
          className={classes.link}
        />
      </Typography>
      <Component open={modalOpen} closeModal={closeModal} onConfirm={onConfirm}>
        {children}
      </Component>
    </span>
  );
};

export default withStyles(styles)(AbortLinkButton);
