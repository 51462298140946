import { envValidation as env } from 'env-validation-js';

/**
 * This sets how many milliseconds before the expiration of a payment order
 * the event local.payment.expired is triggered.
 */
const TIMER_EXPIRED_DELTA: number = env
  .get('REACT_APP_TIMER_EXPIRED_DELTA', 300)
  .asInteger('min:1');

/**
 * This sets how often the Checkout Page will poll the backend to know if the
 * show payment order functionality is up.
 */
const MONITORING_POLLING_INTERVAL: number = env
  .get('REACT_APP_MONITORING_POLLING_INTERVAL', 10)
  .asInteger('min:1');

/**
 * This is the string used as query param in the cancel and confirm redirect
 * URLs, i.e., [continue_url]?[query_param]=[payment_id]
 */
const PAYMENT_ID_QUERY_PARAM: string = env
  .get('REACT_APP_PAYMENT_ID_QUERY_PARAM', 'payment_id')
  .asString();

/**
 * URL of a block explorer website. It should generate a valid URL when a
 * tx id is appended to it.
 */
const BLOCK_EXPLORER_VIEW_TX_URL: string = env
  .get('REACT_APP_BLOCK_EXPLORER_VIEW_TX_URL', 'https://blockexplorer.com/tx/')
  .asUrl();

/**
 * URL of a etherscan website. It should generate a valid URL when a
 * tx id is appended to it.
 */
const ETHERSCAN_VIEW_TX_URL: string = env
  .get('REACT_APP_ETHERSCAN_VIEW_TX_URL', 'https://etherscan.io/tx/')
  .asUrl();

/**
 * Non-expired payment orders with expiration time older than this many
 * milliseconds are rejected.
 */
const PAYMENT_ORDER_EXPIRATION_TIME_VALIDITY_WINDOW: number = env
  .get('REACT_APP_PAYMENT_ORDER_EXPIRATION_TIME_VALIDITY_WINDOW', 3 * 60 * 1000)
  .asInteger('min:1');

/**
 * Payment orders created at least this many milliseconds in the future are
 * rejected.
 */
const PAYMENT_ORDER_CREATED_AT_VALIDITY_WINDOW: number = env
  .get('REACT_APP_PAYMENT_ORDER_CREATED_AT_VALIDITY_WINDOW', 3 * 60 * 1000)
  .asInteger('min:1');

/**
 * Payment orders whose expires_in value differs by the expires_in value
 * calculated by the browser by at least this many milliseconds are rejected.
 */
const PAYMENT_ORDER_EXPIRES_IN_VALIDITY_WINDOW: number = env
  .get('REACT_APP_PAYMENT_ORDER_EXPIRES_IN_VALIDITY_WINDOW', 3 * 60 * 1000)
  .asInteger('min:1');

/**
 * The regular expression used to match the payment order id in the checkout
 * page url.
 *
 * This RegExp takes as payment order id everything from the last slash to
 * the end of the string.
 */
const PAYMENT_ORDER_ID_URL_REGEX: RegExp = new RegExp(
  env.get('REACT_APP_PAYMENT_ORDER_ID_URL_REGEX', '/([^/]+)$').asString()
);

/**
 * URL to retrieve the receipt for a payment order.
 */
const PAYMENT_ORDER_RECEIPT_URL: string = env
  .get('REACT_APP_PAYMENT_ORDER_RECEIPT_URL')
  .asUrl();

/**
 * Socket server URL.
 */
const SOCKET_URL: string = env.get('REACT_APP_SOCKET_URL').asUrl();

/**
 * Checkout Page back end URL.
 */
const API_URL: string = env.get('REACT_APP_API_URL').asUrl();

/**
 * Use credential for authentication.
 */
const SDK_AUTH_WITH_CREDENTIALS = env
  .get('REACT_APP_SDK_AUTH_WITH_CREDENTIALS', true)
  .asBoolean();

/**
 * Socket max reconnection retries.
 */
const SOCKET_MAX_RETRIES: number | undefined = env
  .get('REACT_APP_MAX_RETRIES', undefined)
  .asInteger(['min:0', 'nullable']);

/**
 * True if in production mode, false if in development mode.
 */
const PRODUCTION_MODE: boolean =
  env
    .get('NODE_ENV', 'production')
    .asString('in:production,development,test') === 'production';

/**
 * True if the app must show sandbox payment orders.
 */
const IS_SANDBOX_MODE: boolean = env
  .get('REACT_APP_SANDBOX_MODE', false)
  .asBoolean();

/**
 * URL to the sandbox wallet.
 */
const SANDBOX_WALLET_BASE_URL: string = env
  .get('REACT_APP_SANDBOX_WALLET_BASE_URL')
  .asUrl();

export {
  API_URL,
  BLOCK_EXPLORER_VIEW_TX_URL,
  MONITORING_POLLING_INTERVAL,
  PAYMENT_ID_QUERY_PARAM,
  PAYMENT_ORDER_CREATED_AT_VALIDITY_WINDOW,
  PAYMENT_ORDER_EXPIRATION_TIME_VALIDITY_WINDOW,
  PAYMENT_ORDER_EXPIRES_IN_VALIDITY_WINDOW,
  PAYMENT_ORDER_ID_URL_REGEX,
  PAYMENT_ORDER_RECEIPT_URL,
  PRODUCTION_MODE,
  SANDBOX_WALLET_BASE_URL,
  SDK_AUTH_WITH_CREDENTIALS,
  SOCKET_MAX_RETRIES,
  SOCKET_URL,
  TIMER_EXPIRED_DELTA,
  IS_SANDBOX_MODE,
  ETHERSCAN_VIEW_TX_URL,
};
