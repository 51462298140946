import React from 'react';
import QRCode from 'qrcode.react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Translate from 'react-translate-component';
import { currencyMap } from '../../utils';
import { CryptoCurrency } from '../../redux/types/types';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { StyleRulesCallback } from '@material-ui/core/es';

const QRCodeBlank = require('../../img/QRCode/blank.png');

type Props = {
  paymentUri: string;
  online: boolean;
  classes: ClassNameMap;
  crypto: CryptoCurrency;
};

const styles: StyleRulesCallback = theme => ({
  offlineContainer: {
    width: '230px',
    height: '230px',
    backgroundImage: `url(${QRCodeBlank})`,
    backgroundSize: 'cover',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  offlineText: {
    width: '80px',
    textAlign: 'center',
  },
});

class CustomQRCode extends React.Component<Props> {
  render() {
    const { paymentUri, online, classes, crypto } = this.props;

    if (online) {
      return (
        <div>
          <QRCode
            value={paymentUri}
            size={230}
            bgColor={'#ffffff'}
            fgColor={'#000000'}
            level={'Q'}
            imageSettings={{
              src: currencyMap[crypto].img,
              x: undefined,
              y: undefined,
              height: 32,
              width: 32,
              excavate: true,
            }}
          />
        </div>
      );
    } else {
      return (
        <div className={classes.offlineContainer}>
          <Typography variant="body1" className={classes.offlineText}>
            <Translate content={'common.payment_suspended'} />
          </Typography>
        </div>
      );
    }
  }
}

const QRCodeDecorated = withStyles(styles)(CustomQRCode);

export { QRCodeDecorated as CustomQRCode };
