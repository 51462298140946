import React, { FC, useMemo } from 'react';
import moment from 'moment';
import { Col, Row } from 'react-flexbox-grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Translate from 'react-translate-component';
import { ExternalLinkBlueIcon } from '../common/Icons';
import { Separator } from '../common';
import { currencyMap, getCryptoLocalString, getLocalString } from '../../utils';
import _ from 'lodash';
import { goToLink } from '../../utils/navigation';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { StyleRulesCallback } from '@material-ui/core/es';
import { useCheckout } from '../../redux/checkout/hooks';
import counterpart from 'counterpart';

const styles: StyleRulesCallback = theme => ({
  tableTransactions: {
    fontSize: '12px',
    fontWeight: 600,
    marginBottom: '40px',
    color: theme.typography.body1.color,
  },
  tableTitle: {
    color: theme.palette.primary.main,
    margin: '10px -10px',
  },
  txLabels: {
    color: theme.palette.primary.main,
    display: 'none',
    marginBottom: '5px',
  },
  tableRow: {
    margin: '10px -10px',
  },
  transaction: {
    width: '80%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    cursor: 'pointer',
  },
  iconLink: {
    cursor: 'pointer',
    display: 'inline-block',
  },
  separator: {
    display: 'none',
  },
  importo: {
    textAlign: 'right',
  },
  '@media (max-width: 576px)': {
    separator: {
      display: 'block',
    },
    tableTitle: {
      display: 'none',
    },
    importo: {
      textAlign: 'left',
    },
    txLabels: {
      color: theme.palette.primary.main,
      display: 'block',
    },
  },
});

type Props = {
  language: string;
  classes: ClassNameMap;
};

const Transactions: FC<Props> = ({ classes }) => {
  const { payment } = useCheckout();

  const language = useMemo(() => {
    return counterpart.getLocale();
  }, []);

  return (
    <div className={classes.tableTransactions}>
      <Row>
        <Col sm={12}>
          <Typography variant="body1">
            <Translate content="common.transazioni_ricevute" />
          </Typography>
        </Col>
      </Row>
      <Row className={classes.tableTitle}>
        <Col sm={2}>
          <Translate content="common.ora" />
        </Col>
        <Col sm={4} md={6}>
          <Translate content="common.transazione" />
        </Col>
        <Col sm={3} md={2} style={{ textAlign: 'right' }}>
          <Translate
            content="common.importo_crypto"
            with={{
              crypto_currency:
                currencyMap[payment!.selected_currency!].abbreviation,
            }}
          />
        </Col>
        <Col sm={3} md={2} style={{ textAlign: 'right' }}>
          <Translate
            content="common.importo_currency"
            with={{ currency: payment!.currency! }}
          />
        </Col>
      </Row>
      {payment!.transactions.map((t, idx) => {
        let timeLabel = moment(new Date(t.created_at)).format('HH:mm:ss');
        let transactionID = t.txid;
        let importoBTC = `${getCryptoLocalString(
          t.crypto_amount?.toNumber(),
          language,
          payment!.selected_currency!
        )} ${currencyMap[payment!.selected_currency!].abbreviation}`;
        let importoEUR =
          getLocalString(t.amount!.toNumber(), language) +
          ' ' +
          payment!.currency!;
        const openTransaction = () => {
          const crypto = t.getBlockExplorerLink(payment!.selected_currency!);
          crypto && goToLink(crypto);
        };
        const isLast = idx === payment!.transactions.length - 1;

        return (
          <Row key={_.uniqueId('transaction-')} className={classes.tableRow}>
            <Col xs={4} className={classes.txLabels}>
              <Translate content="common.ora" />
            </Col>
            <Col xs={8} sm={2}>
              {timeLabel}
            </Col>
            <Col xs={4} className={classes.txLabels}>
              <Translate content="common.transazione" />
            </Col>
            <Col xs={8} sm={4} md={6}>
              {transactionID && (
                <React.Fragment>
                  <div
                    onClick={openTransaction}
                    className={classes.transaction}
                  >
                    {transactionID}
                  </div>
                  &nbsp;
                  <ExternalLinkBlueIcon
                    onClick={openTransaction}
                    className={classes.iconLink}
                    width={14}
                    height={15}
                  />
                </React.Fragment>
              )}
            </Col>
            <Col xs={4} className={classes.txLabels}>
              <Translate
                content="common.importo_crypto"
                with={{
                  crypto_currency:
                    currencyMap[payment!.selected_currency!].abbreviation,
                }}
              />
            </Col>
            <Col xs={8} sm={3} md={2} className={classes.importo}>
              {importoBTC}
            </Col>
            <Col xs={4} className={classes.txLabels}>
              <Translate
                content="common.importo_currency"
                with={{ currency: payment!.currency }}
              />
            </Col>
            <Col xs={8} sm={3} md={2} className={classes.importo}>
              {importoEUR}
            </Col>

            {!isLast && (
              <Col xs={12} className={classes.separator}>
                <Separator />
              </Col>
            )}
          </Row>
        );
      })}
    </div>
  );
};

export default withStyles(styles)(Transactions);
