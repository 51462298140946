import { PaymentOrder } from '../../objects/PaymentOrder';
import { createAction } from '@reduxjs/toolkit';
import { CryptoCurrency, PaymentOrderObject } from '../types/types';

export enum CheckoutEvents {
  LOCAL_ERROR = 'local.error',
  LOCAL_PAYMENT_ABORTED = 'local.payment.aborted',
  LOCAL_PAYMENT_ABORTED_REDIRECT = 'local.payment.aborted.redirect',
  LOCAL_PAYMENT_CONFIRMED_REDIRECT = 'local.payment.confirmed.redirect',
  LOCAL_PAYMENT_EXPIRED = 'local.payment.expired',
  LOCAL_PAYMENT_EXPIRED_REDIRECT = 'local.payment.expired.redirect',
  LOCAL_PAYMENT_FOUND = 'local.payment.found',
  LOCAL_PAYMENT_NOT_FOUND = 'local.payment.notfound',
  LOCAL_PAYMENT_REQUEST_DONE = 'local.payment.request.done',
  PAYMENT_UPDATED = 'payment.updated',
  CURRENCY_SELECTED = 'currency.selected',
  USER_SELECTS_CURRENCY = 'select.currency',
  USER_ABORTS_PAYMENT = 'abort.payment',
}

export enum SocketEvents {
  SOCKET_PAYMENT_UPDATED = 'socket.payment.updated',
  RELOAD_PAYMENT_ORDER = 'reload.payment.order',
}

type BusinessInfoObject = {
  business_name: string;
  business_logo: string;
};

export type EventObject = {
  type: string;
  payload: {
    payment?: PaymentOrder;
    business_info?: BusinessInfoObject;
  };
};

export const paymentFound = createAction(CheckoutEvents.LOCAL_PAYMENT_FOUND);

export const paymentNotFound = createAction(
  CheckoutEvents.LOCAL_PAYMENT_NOT_FOUND
);

export const localError = createAction<{ error: unknown | string }>(
  CheckoutEvents.LOCAL_ERROR
);
export type LocalErrorEvent = ReturnType<typeof localError>;
export const localPaymentRequestDone = createAction(
  CheckoutEvents.LOCAL_PAYMENT_REQUEST_DONE
);

export const timerExpired = createAction(CheckoutEvents.LOCAL_PAYMENT_EXPIRED);

export const paymentUpdated = createAction(CheckoutEvents.PAYMENT_UPDATED);

export const userAbortsPayment = createAction(
  CheckoutEvents.USER_ABORTS_PAYMENT
);

export const paymentAborted = createAction(
  CheckoutEvents.LOCAL_PAYMENT_ABORTED
);

export const currencySelected = createAction(CheckoutEvents.CURRENCY_SELECTED);

export const userSelectsCurrency = createAction<{ currency: CryptoCurrency }>(
  CheckoutEvents.USER_SELECTS_CURRENCY
);

export type SelectCurrencyEvent = ReturnType<typeof userSelectsCurrency>;

export const paymentAbortedRedirect = createAction(
  CheckoutEvents.LOCAL_PAYMENT_ABORTED_REDIRECT
);
export const paymentConfirmedRedirect = createAction(
  CheckoutEvents.LOCAL_PAYMENT_CONFIRMED_REDIRECT
);
export const paymentExpiredRedirect = createAction(
  CheckoutEvents.LOCAL_PAYMENT_EXPIRED_REDIRECT
);

export type SocketPaymentUpdatedEvent = {
  type: SocketEvents.SOCKET_PAYMENT_UPDATED;
  payload: { payment: PaymentOrderObject };
};
