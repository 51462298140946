import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Translate from 'react-translate-component';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog/Dialog';
import { Grid, Row } from 'react-flexbox-grid';
import Loader from './Loader';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  container: {
    padding: '60px 30px 65px',
  },
  loading: {
    color: theme.palette.primary.main,
    marginTop: '30px',
  },
});

interface IProps {
  open: boolean;
  classes: ClassNameMap;
}

class AppOfflineDialog extends React.Component<IProps> {
  render() {
    const { open, classes } = this.props;

    return (
      <Dialog
        classes={{
          paper: classes.container,
        }}
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Translate content="messages.offline.title" />
        </DialogTitle>
        <DialogContent>
          <Grid>
            <Row center="xs" middle="xs">
              <Typography variant="body1">
                <Translate content="messages.offline.subtitle.first.text" />
              </Typography>
            </Row>
            <Row center="xs" middle="xs">
              <Loader
                size={20}
                color="primary"
                loading
                style={{ marginRight: '8px' }}
              />
              <Typography variant="body1" className={classes.loading}>
                <Translate content="messages.offline.loading" />
              </Typography>
            </Row>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

const AppOfflineDialogDecorated = withStyles(styles)(AppOfflineDialog);
export { AppOfflineDialogDecorated as AppOfflineDialog };
