import common from './common';

// Language: IT

const messages = {
  ricerca: {
    pagamento: 'Ricerca Ordine di Pagamento in corso...',
  },
  non_trovato: {
    titolo: 'Ordine di Pagamento non trovato',
    parte_1:
      'Se per caso questo dovesse essere un errore, annota il numero dell’ordine di pagamento e ',
    contatta_il_team_ChainsidePay: common.contatta_il_team_ChainsidePay,
    parte_3: ', oppure rivolgiti direttamente all’esercente.',
  },
  generic_error: {
    titolo: 'Ops...',
    sottotitolo: 'Si è verificato un errore imprevisto...',
    parte_1:
      'Prova a ricaricare la pagina oppure annota il numero dell’ordine di pagamento e ',
    contatta_il_team_ChainsidePay: common.contatta_il_team_ChainsidePay,
  },
  waiting: {
    istruzioni:
      'Usa il QR code o l’indirizzo per inviare esattamente l’importo sottostante..',
    istruzioni_qr_code: 'Scansiona QR code',
    istruzioni_sandbox:
      "Apri il Wallet di Sandbox per simulare il flusso dell'utente.",
  },
  waiting_partial: {
    istruzioni:
      'Attenzione, abbiamo ricevuto un pagamento parziale. Completare il pagamento.',
    istruzioni_sandbox:
      "Apri il Wallet di Sandbox per simulare il flusso dell'utente.",
    warning_annullamento_parziale:
      'Il pagamento parziale di %(crypto)s %(currency)s già ricevuto non può essere annullato. Per eventuali richieste di rimborso dovrai contattare direttamente l’esercente.',
  },
  currency_selection: {
    title: 'Selezione criptovaluta del pagamento',
    instructions:
      "Per procedere con l'acquisto online, ti invitiamo a selezionare la criptovaluta preferita (BTC o USDT ERC20).",
    instructions_2:
      'Ti preghiamo di assicurarti attentamente che la rete sia compatibile prima di effettuare qualsiasi transazione. Non ci assumiamo responsabilità per eventuali fondi persi a causa di una selezione errata della rete.',
  },
  waiting_expired: {
    titolo: 'Ancora un momento...',
    sottotitolo:
      'Siamo in attesa che il server confermi o meno l’avvenuto pagamento.',
    received:
      'Abbiamo ricevuto un pagamento di %(crypto)s %(currency)s, pari a %(amount)s.',
    quelche_minuto: 'L’operazione potrebbe richiedere alcuni minuti...',
  },
  payment_aborted: {
    titolo: 'Pagamento annullato',
    parte_1:
      'Ogni importo ricevuto dopo l’annullamento non verrà considerato. Se per caso questo dovesse essere un errore, scarica la ricevuta del pagamento e ',
    parte_2: common.contatta_il_team_ChainsidePay,
    parte_3: ', oppure rivolgiti direttamente all’esercente.',
    attenzione: 'Attenzione, abbiamo ricevuto un pagamento parziale',
    pagamento_parziale:
      'Abbiamo ricevuto un totale di %(crypto)s %(currency)s, pari a %(amount)s. Per una eventuale richiesta di rimborso scarica la ricevuta e rivolgiti direttamente all’esercente.',
  },
  payment_expired: {
    titolo: 'Tempo scaduto',
    parte_1:
      'Il tempo a tua disposizione per effettuare il pagamento è scaduto pertanto l’ordine è stato annullato.',
    parte_2:
      'Qualsiasi importo ricevuto dopo la scadenza non verrà considerato.',
    parte_3: 'Per qualsiasi problema scarica la ricevuta del pagamento e ',
    parte_4: common.contatta_il_team_ChainsidePay,
    parte_5: ', oppure rivolgiti direttamente all’esercente.',
    attenzione: 'Attenzione, abbiamo ricevuto un pagamento parziale',
    pagamento_parziale:
      'Abbiamo ricevuto un totale di %(crypto)s %(currency)s, pari a %(amount)s. Per una eventuale richiesta di rimborso scarica la ricevuta e rivolgiti direttamente all’esercente.',
  },
  payment_complete: {
    titolo: 'Pagamento ricevuto',
    messaggio:
      'Torna al sito dell’esercente per completare la procedura d’acquisto, oppure scarica la ricevuta del pagamento, potrebbe esserti utile in futuro.',
    attenzione:
      'Attenzione, abbiamo ricevuto %(crypto)s %(currency)s più del dovuto',
    pagamento_completo:
      'Abbiamo ricevuto un totale di %(crypto)s %(currency)s, pari a %(amount)s. Per una eventuale richiesta di rimborso scarica la ricevuta e rivolgiti direttamente all’esercente.',
  },
  redirecting: {
    titolo: 'Reindirizzamento in corso...',
    messaggio: "Stai per essere reindirizzato al sito dell'esercente.",
  },
  payment_network_dispute: {
    title: 'Disputa aperta sul pagamento',
    subtitle: {
      row1: {
        text: 'Per maggiori informazioni riguardo le dispute di pagamenti ',
        link: 'consulta le nostre FAQ',
      },
      row2: {
        text: 'oppure scarica la ricevuta del pagamento e ',
        link: 'contatta il team ChainsidePay',
      },
    },
  },
  payment_chargeback: {
    title: 'Pagamento in Chargeback',
    subtitle: {
      row1: {
        text: "L'ordine d'acquisto potrebbe annullato dall'esercente.",
      },
      row2: {
        text: 'Per maggiori informazioni riguardo le chargeback ',
        link: 'consulta le nostre FAQ.',
      },
    },
  },
  offline: {
    title: 'Problemi nel monitoraggio della rete...',
    subtitle: {
      first: {
        text:
          'Stiamo provvedendo a risolvere il problema. Nel frattempo il' +
          ' pagamento è stato temporaneamente sospeso. Ti invitiamo ad' +
          ' aspettare qualche secondo',
      },
    },
    loading: 'Tentativo di riconnessione in corso...',
  },
  information_message: {
    title_part_1: 'Vuoi pagare con ',
    title_part_2: 'crypto exchange ',
    title_part_3: '(ad esempio Binance, Kraken, ecc.)?',
    subtitle: {
      row1: {
        text: "1. Copia l'indirizzo del destinatario indicato di seguito.",
      },
      row2: {
        text:
          "2. Inserisci l'importo visualizzato di seguito PIÙ la commissione del fornitore di exchange.",
      },
      row3: {
        text: '3. Completa e preleva. ',
        link: 'Scarica le istruzioni complete',
      },
    },
  },
};

export default messages;
