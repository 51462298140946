import common from './common';

// Language: EN

const messages = {
  ricerca: {
    pagamento: 'Searching Payment Order...',
  },
  non_trovato: {
    titolo: 'Payment Order not found',
    parte_1:
      'If you believe this is an error, take note of the payment order id and ',
    contatta_il_team_ChainsidePay: common.contatta_il_team_ChainsidePay,
    parte_3: '. Alternatively, contact the merchant directly.',
  },
  generic_error: {
    titolo: 'Ops...',
    sottotitolo: 'Unexpected error...',
    parte_1:
      'Try reload the page. Alternatively take note of the payment order id and ',
    contatta_il_team_ChainsidePay: common.contatta_il_team_ChainsidePay,
  },
  waiting: {
    istruzioni:
      'Scan the QR code or use the address below to send exactly the amount below.',
    istruzioni_qr_code: 'Scan QR code',
    istruzioni_sandbox:
      'Open the Sandbox Wallet to simulate the behaviour of the user.',
  },
  waiting_partial: {
    istruzioni: 'We received a partial amount. Please, complete the payment.',
    istruzioni_sandbox:
      'Open the Sandbox Wallet to simulate the behaviour of the user.',
    warning_annullamento_parziale:
      'The partial payment of %(crypto)s %(currency)s already received cannot be reverted. To claim a refund you will need to contact the merchant directly.',
  },
  currency_selection: {
    title: 'Cryptocurrency payment selection',
    instructions:
      'Please choose your preferred cryptocurrency (BTC or USDT ERC20) to proceed with your online purchase.',
    instructions_2:
      'Kindly ensure that you carefully verify the network compatibility before proceeding with any transactions. We cannot be held responsible for any funds lost due to incorrect network selection.',
  },
  waiting_expired: {
    titolo: 'Just a moment...',
    sottotitolo:
      'We are waiting the server to confirm the payment completed successfully.',
    received:
      'We received a payment of %(crypto)s %(currency)s, or %(amount)s.',
    quelche_minuto: 'This may take a few minutes...',
  },
  payment_aborted: {
    titolo: 'Payment aborted',
    parte_1:
      'Every amount received after the abortion will not be taken into account. If you believe this is an error, download the payment receipt and ',
    parte_2: common.contatta_il_team_ChainsidePay,
    parte_3: '. Alternatively, contact the merchant directly.',
    attenzione: 'We received a partial amount',
    pagamento_parziale:
      'We received a payment of %(crypto)s %(currency)s, or %(amount)s. To claim a refund download the paymnet receipt and contact the merchant directly.',
  },
  payment_expired: {
    titolo: 'Time expired',
    parte_1:
      'The time to complete the payment is expired and the payment order has been cancelled.',
    parte_2:
      'Every amount received after the expiration will not be taken into account.',
    parte_3: 'For any problem, download the payment receipt and ',
    parte_4: common.contatta_il_team_ChainsidePay,
    parte_5: '. Alternatively, contact the merchant directly.',
    attenzione: 'We received a partial amount',
    pagamento_parziale:
      'We received a payment of %(crypto)s %(currency)s, or %(amount)s. To claim a refund download the payment receipt and contact the merchant directly',
  },
  payment_complete: {
    titolo: 'Payment received',
    messaggio:
      'You can now go back to the merchant website to complete your purchase. You can also download the payment receipt below, it may be helpful in the future.',
    attenzione: 'We received %(crypto)s %(currency)s more than expected',
    pagamento_completo:
      'We received a total of %(crypto)s %(currency)s, or %(amount)s. To claim a refund download the payment receipt and contact the merchant directly.',
  },
  redirecting: {
    titolo: 'Redirecting...',
    messaggio: 'You are being redirected to the merchant website.',
  },
  payment_network_dispute: {
    title: 'Payment in Network Dispute',
    subtitle: {
      row1: {
        text: 'To learn about network dispute payments ',
        link: 'read our FAQs.',
      },
      row2: {
        text: 'or download the payment receipt and ',
        link: common.contatta_il_team_ChainsidePay,
      },
    },
  },
  payment_chargeback: {
    title: 'Chargeback Payment',
    subtitle: {
      row1: {
        text: 'The payment order may have been cancelled by the merchant.',
      },
      row2: {
        text: 'To learn about chargebacks ',
        link: 'read our FAQs.',
      },
    },
  },
  offline: {
    title: 'Problems with blockchain monitoring...',
    subtitle: {
      first: {
        text:
          'We are trying to solve the problem. Meanwhile, the payment was' +
          ' suspended. Please wait a few seconds.',
      },
    },
    loading: 'Trying to reconnect...',
  },
  information_message: {
    title_part_1: 'Do you want to pay with a ',
    title_part_2: 'crypto exchange ',
    title_part_3: '(e.g. Binance, Kraken, ecc.)?',
    subtitle: {
      row1: {
        text: '1. Please copy the receiving address below.',
      },
      row2: {
        text:
          '2. Insert the amount displayed below PLUS the exchange provider fee',
      },
      row3: {
        text: '3. Complete and withdraw. ',
        link: 'Download full instructions',
      },
    },
  },
};

export default messages;
