import React, { FC, useCallback, useMemo, useState } from 'react';
import classnames from 'classnames';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Translate from 'react-translate-component';
import Button from '@material-ui/core/Button';
import { Copyer, OrderHeader, Separator } from '../common';

import { CopyContentIcon } from '../common/Icons';
import { getCryptoLocalString, getLocalString } from '../../utils';
import { CountdownPayment } from '../common/CountdownPayment';
import { CustomQRCode } from '../common/CustomQRCode';
import { IS_SANDBOX_MODE } from '../../config';
import { InformationMessage } from '../common/InformationMessage';
import { getDisplayAmount } from '../../utils/currency_utils/currency';
import { AbortLinkButton } from '../common/';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { useCheckout } from '../../redux/checkout/hooks';
import counterpart from 'counterpart';
import { StyleRulesCallback } from '@material-ui/core/es';

const styles: StyleRulesCallback = theme => ({
  container: {
    marginTop: '20px',
    marginBottom: '20px',
    minHeight: '100px',
  },
  istructions: {
    fontSize: '13px',
    display: 'block',
    textAlign: 'center',
    marginBottom: '10px',
  },
  transactionInfoContainer: {
    padding: '20px',
    //@ts-ignore
    backgroundColor: theme.palette.primary.bg,
    marginBottom: '20px',
  },
  label: {
    fontSize: '12px',
    color: theme.typography.body1.color,
  },
  labelBTCUri: {
    fontSize: '14px',
    color: theme.palette.grey[4],
    width: 'inherit',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  btc: {
    fontSize: '20px',
    color: theme.palette.grey[4],
    marginTop: '0px',
  },
  eur: {
    color: theme.palette.grey[4],
  },
  btcurl: {
    color: theme.palette.grey[4],
  },
  icon: {
    position: 'absolute',
    top: '0',
    right: '10px',
    cursor: 'pointer',
    width: '20px',
    height: '20px',
  },
  buttonIcon: {
    left: '30px',
    position: 'absolute',
    top: '11px',
  },
  button: {
    borderRadius: '0',
    fontWeight: 'bold',
    marginBottom: '30px',
    padding: '12px 16px',
  },
  qrcode: {
    textAlign: 'right',
  },
  hidden: {
    display: 'none',
  },
  hiddenOnDesktop: {
    display: 'none',
  },
  hiddenOnMobile: {
    display: 'block',
  },
  qrCodeText: {
    marginTop: '0px',
    display: 'flex',
    justifyContent: 'center',
    width: '230px',
    float: 'right',
  },
  qrCodeTextColDesktop: {
    justifyContent: 'right',
  },
  '@media (max-width: 768px)': {
    qrcode: {
      textAlign: 'center',
      margin: '20px 0',
    },
    hiddenOnDesktop: {
      display: 'block',
    },
    hiddenOnMobile: {
      display: 'none',
    },
    qrCodeText: {
      display: 'none',
    },
  },
});

type Props = {
  classes: ClassNameMap;
};

const Waiting: FC<Props> = ({ classes }) => {
  const { businessInfo, payment, online } = useCheckout();
  const [showQRcode, setShowQRcode] = useState(false);

  const displayQRcode = useCallback(() => {
    setShowQRcode(true);
  }, []);

  const language = useMemo(() => {
    return counterpart.getLocale();
  }, []);

  const timeLeft = payment!.getTimeLeft();
  return (
    <div>
      <OrderHeader
        businessName={businessInfo?.business_name}
        businessLogo={businessInfo?.business_logo}
        payment={payment}
      />
      <Grid className={classes.container}>
        <Row>
          <Col sm={12}>
            <InformationMessage crypto_currency={payment!.selected_currency!} />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={7} lg={8}>
            <Typography variant="body1" className={classes.istructions} />
          </Col>
        </Row>
        <Row style={{ marginBottom: '40px' }}>
          <Col sm={12} md={7} lg={8}>
            <div className={classes.transactionInfoContainer}>
              <Row>
                <Col sm={12} md={3}>
                  <Typography variant="body1" className={classes.label}>
                    <Translate content="common.importo" />
                  </Typography>
                </Col>
                <Col sm={12} md={9}>
                  <Row style={{ position: 'relative' }}>
                    <Col xs={10}>
                      <Typography variant="body1" className={classes.btc}>
                        {getDisplayAmount(
                          language,
                          payment!.crypto_amount!,
                          payment!.selected_currency!
                        )}
                      </Typography>
                    </Col>
                    <Col className={classes.icon}>
                      <Copyer
                        copyItem={<CopyContentIcon />}
                        onCopyItem={<Translate content="common.copiato" />}
                        textToCopy={getCryptoLocalString(
                          payment!.crypto_amount!.toNumber(),
                          language,
                          payment!.selected_currency!
                        )}
                      />
                    </Col>
                    <Col xs={10}>
                      <Typography variant="body1" className={classes.eur}>
                        {getLocalString(payment!.amount.toNumber(), language)}{' '}
                        {payment!.currency}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Separator />
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={3}>
                  <Typography variant="body1" className={classes.label}>
                    <Translate content="common.indirizzo" />
                  </Typography>
                </Col>
                <Col sm={12} md={9}>
                  {online && (
                    <Row style={{ position: 'relative' }}>
                      <Col xs={10}>
                        <Typography
                          variant="body1"
                          className={classes.labelBTCUri}
                        >
                          {payment!.address}
                        </Typography>
                      </Col>
                      <Col className={classes.icon}>
                        <Copyer
                          copyItem={<CopyContentIcon />}
                          onCopyItem={<Translate content="common.copiato" />}
                          textToCopy={payment!.address}
                        />
                      </Col>
                    </Row>
                  )}
                  {!online && (
                    <Typography variant="body1" className={classes.labelBTCUri}>
                      <Translate content="common.payment_suspended" />
                    </Typography>
                  )}
                </Col>
              </Row>
            </div>
            <Button
              variant="raised"
              fullWidth
              color="primary"
              className={classes.button}
              href={payment!.getUri()}
              target="_blank"
              disabled={!online}
            >
              <Translate
                content={
                  IS_SANDBOX_MODE
                    ? 'common.apri_in_wallet_sandbox'
                    : 'common.apri_in_wallet'
                }
              />
            </Button>
            <div className={classes.hiddenOnDesktop}>
              <Button
                id="btnShowQRCode"
                variant="raised"
                fullWidth
                color="primary"
                className={classnames({
                  [classes.button]: true,
                  [classes.hiddenOnMobile]: showQRcode,
                })}
                onClick={displayQRcode}
              >
                <Translate content="common.mostra_qrcode" />
              </Button>
            </div>
          </Col>
          <Col sm={12} md={5} lg={4}>
            <div
              id="QRCode"
              className={classnames({
                [classes.hiddenOnMobile]: !showQRcode,
                [classes.qrcode]: true,
              })}
            >
              <CustomQRCode
                paymentUri={payment!.getUri()}
                online={!!online}
                crypto={payment!.selected_currency!}
              />
            </div>
          </Col>
          {!IS_SANDBOX_MODE && (
            <>
              <Col sm={12} md={7} lg={8}>
                <CountdownPayment online={!!online} timeLeft={timeLeft} />
              </Col>
              <Col
                sm={12}
                md={5}
                lg={4}
                className={classnames({
                  [classes.hiddenOnMobile]: !showQRcode,
                  [classes.qrCodeTextColDesktop]: true,
                })}
              >
                <div className={classes.qrCodeText}>
                  <Typography
                    variant="body1"
                    component="span"
                    align="center"
                    style={{ marginTop: '0px' }}
                  >
                    <Translate content="messages.waiting.istruzioni_qr_code" />
                  </Typography>
                </div>
              </Col>
            </>
          )}
        </Row>
        <Row>
          <Col sm={12}>
            <AbortLinkButton />
            <Separator />
            <Typography variant="body1">
              <Translate
                content={
                  IS_SANDBOX_MODE
                    ? 'common.contatta_esercente_sandbox'
                    : 'common.contatta_esercente'
                }
              />
            </Typography>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(Waiting);
