import { StateMachineInterface } from 'redux-sigma';
import { useDispatch } from 'react-redux';
import { useLayoutEffect, useRef } from 'react';
import { dequal } from 'dequal';

function useDeepCompareMemoize(value: DependencyList) {
  const ref = useRef<DependencyList>();

  const signalRef = useRef<number>(0);

  if (!dequal(value, ref.current)) {
    ref.current = value;
    signalRef.current += 1;
  }

  return [signalRef.current];
}

function useDeepCompareLayoutEffect(
  callback: LayoutEffectCallback,
  dependencies: DependencyList
): UseLayoutEffectReturn {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useLayoutEffect(callback, useDeepCompareMemoize(dependencies));
}
export function useStateMachine<SM extends string, C>(
  stm: StateMachineInterface<any, SM, C>,
  context: C
): void {
  const dispatch = useDispatch();

  useDeepCompareLayoutEffect(() => {
    dispatch(stm.start(context));

    return () => {
      dispatch(stm.stop());
    };
  }, [dispatch, stm, context]);
}

type UseLayoutEffectParams = Parameters<typeof useLayoutEffect>;
type LayoutEffectCallback = UseLayoutEffectParams[0];
type DependencyList = UseLayoutEffectParams[1];
type UseLayoutEffectReturn = ReturnType<typeof useLayoutEffect>;
