import React, { ReactNode } from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import { Col, Row } from 'react-flexbox-grid';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { StyleRulesCallback } from '@material-ui/core/es';

const styles: StyleRulesCallback = theme => ({
  messageContainer: {
    margin: '0 0 30px 0px',
    textAlign: 'center',
    // @ts-ignore
    background: theme.palette.alert.backgroundColor,
    padding: '20px 30px',
  },
  title: {
    fontSize: '13px',
    // @ts-ignore
    color: theme.palette.orange.dark,
    marginBottom: '10px',
  },
  message: {
    fontSize: '13px',
    color: theme.palette.grey['4'],
  },
});

type Props = {
  title: ReactNode;
  message: ReactNode;
  classes: ClassNameMap;
};

class WarningMessage extends React.Component<Props> {
  render() {
    const { classes, title, message } = this.props;
    return (
      <Row center="sm">
        <Col xs={12}>
          <div className={classes.messageContainer}>
            <Typography variant="body1" className={classes.title}>
              {title}
            </Typography>
            <Typography variant="body1" className={classes.message}>
              {message}
            </Typography>
          </div>
        </Col>
      </Row>
    );
  }
}

export default withStyles(styles)(WarningMessage);
