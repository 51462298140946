import {
  CurrencyBTCICon,
  CurrencyUSDTICon,
} from '../../components/common/Icons';
import { getCryptoLocalString } from '../numbers';

import btcImage from '../../../src/components/common/Icons/img/currency/BTC.png';
import usdtImage from '../../../src/components/common/Icons/img/currency/USDT.png';
import { CryptoCurrency } from '../../redux/types/types';
import { Decimal } from 'decimal.js';
export const currencyMap = {
  BTC: {
    icon: CurrencyBTCICon,
    img: btcImage,
    label: 'Bitcoin',
    extendedAbbreviation: 'BTC',
    abbreviation: 'BTC',
    minimumFractionDigits: 8,
  },
  'USDT-ERC20': {
    icon: CurrencyUSDTICon,
    img: usdtImage,
    label: 'Tether USD',
    extendedAbbreviation: 'USDT ERC20',
    abbreviation: 'USDT',
    minimumFractionDigits: 6,
  },
};

export const getCryptoAbbreviation = (selected_currency?: CryptoCurrency) => {
  return selected_currency ? currencyMap[selected_currency].abbreviation : '';
};

export const getDisplayAmount = (
  language: string,
  amount: Decimal,
  currency?: CryptoCurrency
) => {
  return `${getCryptoLocalString(
    amount.toNumber(),
    language,
    currency
  )} ${getCryptoAbbreviation(currency)}`;
};
