import React, { FC } from 'react';
import AppBar from '@material-ui/core/AppBar';
import { withStyles } from '@material-ui/core';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Translate from 'react-translate-component';
import { HeaderLogo } from './Icons';
import { StyleRulesCallback } from '@material-ui/core/es';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

const styles: StyleRulesCallback = theme => ({
  header: {
    position: 'relative',
    // @ts-ignore
    background: theme.palette.primary.bg,
    boxShadow: '0 1px 1px 0 #d7dfe5',
  },
  logo: {
    padding: '15px',
    textAlign: 'left',
  },
  title: {
    padding: '20px 15px',
    fontFamily: 'Lato',
    fontSize: '11px',
    fontWeight: 600,
    color: '#7a868d',
    textTransform: 'uppercase',
    textAlign: 'right',
  },
  '@media (max-width: 575px)': {
    logo: {
      padding: '20px 0 0 0',
      textAlign: 'center',
    },
    title: {
      paddingTop: '10px',
      textAlign: 'center',
    },
  },
});

type Props = {
  visible: boolean;
  payment_uuid: string | null;
  classes: ClassNameMap;
};

const Header: FC<Props> = ({ classes, visible, payment_uuid }) => {
  return visible ? (
    <AppBar className={classes.header}>
      <Grid>
        <Row center="sm">
          <Col xs={12} md={11} lg={10}>
            <Row>
              <Col xs={12} sm={2} className={classes.logo}>
                <HeaderLogo />
              </Col>
              <Col xs={12} sm={10} className={classes.title}>
                <Translate content="common.ordine_pagamento" />
                &nbsp;
                {payment_uuid}
              </Col>
            </Row>
          </Col>
        </Row>
      </Grid>
    </AppBar>
  ) : null;
};

export default withStyles(styles)(Header);
