import { Decimal } from 'decimal.js';

function satoshiToBtc(amount: number | Decimal): Decimal {
  return new Decimal(amount).times(new Decimal('1e-8'));
}

function btcToSatoshi(amount: Decimal): Decimal {
  return amount.times(new Decimal('1e8'));
}

export { satoshiToBtc, btcToSatoshi };
