import React, { FC } from 'react';
import Translate from 'react-translate-component';
import counterpart from 'counterpart';
import { withStyles } from '@material-ui/core';
import { UserMessage, PaymentOrderLabel } from '../common';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { StyleRulesCallback } from '@material-ui/core/es';
import { useCheckout } from '../../redux/checkout/hooks';

const styles: StyleRulesCallback = theme => ({
  icon: {
    fontSize: '32px',
    color: theme.palette.grey['4'],
  },
  title: {
    color: theme.palette.grey['4'],
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
});

type Props = {
  classes: ClassNameMap;
};

const ErrorPage: FC<Props> = ({ classes }) => {
  const { payment } = useCheckout();

  return (
    <div style={{ marginTop: '80px' }}>
      <UserMessage
        icon={
          <Translate
            content="messages.generic_error.titolo"
            className={classes.icon}
          />
        }
        title={
          <Translate
            content="messages.generic_error.sottotitolo"
            className={classes.title}
          />
        }
        message={
          <React.Fragment>
            <Translate content="messages.generic_error.parte_1" />
            <br />
            <a href={counterpart('links.contact_us')} className={classes.link}>
              <Translate content="messages.generic_error.contatta_il_team_ChainsidePay" />
            </a>
            .
          </React.Fragment>
        }
      />
      <PaymentOrderLabel paymentId={payment?.uuid ?? ''} />
    </div>
  );
};

export default withStyles(styles)(ErrorPage);
