import React from 'react';
import Typography from '@material-ui/core/Typography/Typography';
import Loader from './Loader';
import Translate from 'react-translate-component';
import Countdown from './Countdown';

type Props = {
  online: boolean;
  timeLeft: number;
};

class CountdownPayment extends React.Component<Props> {
  render() {
    const { online, timeLeft } = this.props;
    if (!online) {
      return (
        <Typography variant="body1" align="center">
          <Translate content="common.payment_temporary_suspended" />
        </Typography>
      );
    } else {
      return (
        <Typography
          variant="body1"
          component="span"
          align="center"
          style={{ marginTop: '-30px' }}
        >
          <Loader style={{ display: 'inline-block' }} size={14} loading />
          &nbsp;
          <Translate content="common.attesa_pagamento" />
          &nbsp; (<Countdown milliseconds={timeLeft} />
          &nbsp;
          <Translate content="common.rimanenti" />)
        </Typography>
      );
    }
  }
}

export { CountdownPayment };
