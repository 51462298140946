const PAYMENT_ORDER_FRONT_END_STATUS = {
  PENDING: 'pending',
  PARTIAL: 'partial',
  ABORTED: 'aborted',
  CONFIRMED: 'confirmed',
  EXPIRED: 'expired',
  CHARGEBACK: 'chargeback',
  NETWORK_DISPUTE: 'network_dispute',
};

export { PAYMENT_ORDER_FRONT_END_STATUS };
