// Language: IT

export const common = {
  attenzione: 'Attenzione',
  ordine_pagamento: 'ORDINE DI PAGAMENTO:',
  contatta_il_team_ChainsidePay: 'contatta il team ChainsidePay',
  ordine: 'Ordine',
  scarica_ricevuta_button: 'SCARICA RICEVUTA',
  prosegui_button: 'PROSEGUI',
  apri_in_wallet: 'APRI IN WALLET',
  mostra_qrcode: 'MOSTRA QRCODE',
  contatta_esercente:
    'L’indirizzo è associato esclusivamente al presente ordine. Qualsiasi importo ricevuto dopo la scadenza dell’ordine non verrà considerato. Per qualsiasi problema rivolgersi direttamente l’esercente.',
  annulla_pagamento_button: 'Annulla Pagamento',
  annulla_pagamento_warning: 'Sei sicuro di voler annullare il pagamento?',
  annulla_pagamento_progress: 'Annullamento pagamento in corso...',
  importo: 'IMPORTO',
  importo_mancante: 'IMPORTO MANCANTE',
  indirizzo: 'INDIRIZZO',
  attesa_pagamento: 'In attesa del pagamento ',
  rimanenti: 'rimanenti',
  transazioni_ricevute: 'TRANZAZIONI RICEVUTE',
  ora: 'Ora',
  transazione: 'Transazione',
  importo_crypto: 'Importo %(crypto_currency)s',
  importo_currency: 'Importo %(currency)s',
  conferma_button: 'CONFERMA',
  indietro_button: 'INDIETRO',
  copiato: 'COPIATO',
  payment_suspended: 'Pagamento sospeso...',
  payment_temporary_suspended: 'Pagamento temporaneamente sospeso',
  contatta_esercente_sandbox:
    "Usa la versione di sandbox della Checkout Page per testare l'integrazione del tuo webPOS.",
  apri_in_wallet_sandbox: 'Apri il Wallet di Sandbox',
};

export default common;
