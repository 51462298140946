import * as React from 'react';

import Translate from 'react-translate-component';

import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { StyleRulesCallback } from '@material-ui/core/es';

const styles: StyleRulesCallback = theme => ({
  downloadButton: {
    borderRadius: '0',
    backgroundColor: '#FFF',
    color: theme.palette.primary.main,
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  '@media (max-width: 576px)': {
    downloadButton: {
      margin: '5px 0px',
    },
  },
});

type Props = {
  onDownload: () => void;
  classes: ClassNameMap;
};

class DownloadButton extends React.Component<Props> {
  render() {
    const { classes, onDownload } = this.props;

    return (
      <Button
        variant="raised"
        fullWidth
        color="default"
        className={classes.downloadButton}
        onClick={onDownload}
      >
        <Translate content="common.scarica_ricevuta_button" />
      </Button>
    );
  }
}

const StyledDownloadButton = withStyles(styles)(DownloadButton);

export { StyledDownloadButton as DownloadButton };
