import * as React from 'react';

import Translate from 'react-translate-component';

import { Row, Col } from 'react-flexbox-grid';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { withStyles } from '@material-ui/core/styles';
import { FC, PropsWithChildren } from 'react';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { StyleRulesCallback } from '@material-ui/core/es';

const styles: StyleRulesCallback = theme => ({
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  confirmButton: {
    borderRadius: '0',
    color: '#FFF',
    backgroundColor: theme.palette.error.main,
    fontWeight: 'bold',
    marginBottom: '10px',
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
  },
  backButton: {
    borderRadius: '0',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
});

type Props = {
  open: boolean;
  closeModal: () => void;
  onConfirm: () => void;
  classes: ClassNameMap;
};

const AbortConfirmation: FC<PropsWithChildren<Props>> = ({
  classes,
  open,
  closeModal,
  onConfirm,
  children,
}) => {
  return (
    <Dialog
      open={open}
      onClose={closeModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        <Translate
          content="common.annulla_pagamento_warning"
          className={classes.link}
        />
      </DialogTitle>
      <DialogContent className={classes.content}>
        {children}
        <Row>
          <Col xs={12} sm={6}>
            <Button
              variant="raised"
              fullWidth
              color="primary"
              className={classes.backButton}
              onClick={closeModal}
            >
              <Translate content="common.indietro_button" />
            </Button>
          </Col>
          <Col xs={12} sm={6}>
            <Button
              variant="raised"
              fullWidth
              color="primary"
              className={classes.confirmButton}
              onClick={onConfirm}
            >
              <Translate content="common.conferma_button" />
            </Button>
          </Col>
        </Row>
      </DialogContent>
    </Dialog>
  );
};

const StyledAbortConfirmation = withStyles(styles)(AbortConfirmation);

export { StyledAbortConfirmation as AbortConfirmation };
