import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Translate from 'react-translate-component';
import 'moment/locale/it';
import { Separator } from '../common';
import { PaymentOrder } from '../../objects/PaymentOrder';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { StyleRulesCallback } from '@material-ui/core/es';

const styles: StyleRulesCallback = theme => ({
  container: {
    marginTop: '20px',
    marginBottom: '20px',
    minHeight: '100px',
  },
  rectPlaceholder: {
    width: '120px',
    height: '24px',
    background: '#f0f0f0',
    marginBottom: '5px',
  },
  roundPlaceholder: {
    width: '80px',
    height: '80px',
    float: 'right',
    border: '1px solid #ddd',
    background: '#f0f0f0',
    borderRadius: '50%',
  },
  businessName: {
    height: '24px',
    fontFamily: 'Lato',
    fontSize: '20px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#444444',
    marginBottom: '10px',
  },
  businessLogo: {
    width: '80px',
    height: '80px',
    float: 'right',
    borderRadius: '50%',
  },
});

type Props = {
  businessName?: string;
  businessLogo?: string | null;
  payment?: PaymentOrder;
  classes: ClassNameMap;
};

const OrderHeader = (props: Props) => {
  const { classes } = props;
  return (
    <Grid className={classes.container}>
      <Row>
        <Col xs={8} sm={10}>
          {props.businessName == null ? (
            <div className={classes.rectPlaceholder} />
          ) : (
            <div className={classes.businessName}>{props.businessName}</div>
          )}
          {props.payment == null ? (
            <div className={classes.rectPlaceholder} />
          ) : (
            <React.Fragment>
              <Typography variant="body1">
                <Translate content="common.ordine" />: {props.payment.reference}
              </Typography>
              <Typography variant="body1">{props.payment.details}</Typography>
            </React.Fragment>
          )}
        </Col>
        <Col xs={4} sm={2}>
          {!props.businessLogo ? (
            <div className={classes.roundPlaceholder} />
          ) : (
            <img
              alt={props.businessName}
              src={props.businessLogo}
              className={classes.businessLogo}
            />
          )}
        </Col>
        <Col xs={12}>
          <Separator />
        </Col>
      </Row>
    </Grid>
  );
};

export default withStyles(styles)(OrderHeader);
