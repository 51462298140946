import React from 'react';
import Typography from '@material-ui/core/Typography';
import Translate from 'react-translate-component';

type Props = {
  paymentId: string;
};

class PaymentOrderLabel extends React.Component<Props> {
  render() {
    return (
      <Typography variant="body1" style={{ textAlign: 'center' }}>
        <Translate content="common.ordine_pagamento" />
        &nbsp;
        {this.props.paymentId}
      </Typography>
    );
  }
}

export default PaymentOrderLabel;
