import React, { FC, ReactNode } from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import { Row, Col } from 'react-flexbox-grid';
import { StyleRulesCallback } from '@material-ui/core/es';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

const styles: StyleRulesCallback = theme => ({
  title: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  subtitle: {
    color: theme.palette.grey['4'],
    marginBottom: '10px',
  },
  messageContainer: {
    textAlign: 'center',
    marginBottom: '30px',
  },
  submessage: {
    fontSize: '12px',
    marginBottom: '10px',
  },
});

type Props = {
  title: ReactNode;
  message?: string | ReactNode;
  submessage?: ReactNode;
  classes: ClassNameMap;
  icon: ReactNode;
};

const UserMessage: FC<Props> = ({
  classes,
  icon,
  title,
  message,
  submessage,
}) => {
  return (
    <Row center="sm">
      <Col xs={12} sm={8}>
        <div className={classes.messageContainer}>
          <Typography variant="title" component="span">
            {icon}
          </Typography>
          <Typography variant="title" className={classes.title}>
            {title}
          </Typography>
          <Typography variant="body1">{message}</Typography>
          {submessage && (
            <div>
              <Typography variant="body1" className={classes.submessage}>
                {submessage}
              </Typography>
            </div>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default withStyles(styles)(UserMessage);
