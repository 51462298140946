import React, { FC } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Translate from 'react-translate-component';
import { OrderHeader, Separator } from '../common';

import { CountdownPayment } from '../common/CountdownPayment';
import { IS_SANDBOX_MODE } from '../../config';
import { CurrencyButton } from '../common/CurrencyButton';
import AbortLinkButton from '../common/AbortLinkButton';
import { StyleRulesCallback } from '@material-ui/core/es';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { CustomTheme } from '../../styles/theme';
import { useCheckout } from '../../redux/checkout/hooks';

const styles: StyleRulesCallback<string> = (theme: CustomTheme) => ({
  container: {
    marginTop: '20px',
    marginBottom: '20px',
    minHeight: '100px',
  },
  helpContainer: {
    marginBottom: '10px',
  },
  instructions: {
    fontSize: '13px',
    display: 'block',
    textAlign: 'center',
    marginBottom: '10px',
  },
  title: {
    fontSize: '18px',
    textAlign: 'center',
    marginBottom: '10px',
    color: '#444444',
  },
  transactionInfoContainer: {
    padding: '20px',
    //@ts-ignore
    backgroundColor: theme.palette.primary.bg,
    marginBottom: '20px',
  },
  label: {
    fontSize: '12px',
    color: theme.typography.body1.color,
  },
  labelBTCUri: {
    fontSize: '14px',
    color: theme.palette.grey[4],
    width: 'inherit',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  btc: {
    fontSize: '20px',
    color: theme.palette.grey[4],
    marginTop: '0px',
  },
  eur: {
    color: theme.palette.grey[4],
  },
  btcurl: {
    color: theme.palette.grey[4],
  },
  icon: {
    position: 'absolute',
    top: '0',
    right: '10px',
    cursor: 'pointer',
    width: '20px',
    height: '20px',
  },
  buttonIcon: {
    left: '30px',
    position: 'absolute',
    top: '11px',
  },
  button: {
    borderRadius: '0',
    fontWeight: 'bold',
    marginBottom: '30px',
    padding: '12px 16px',
  },
  qrcode: {
    textAlign: 'right',
  },
  hidden: {
    display: 'none',
  },
  hiddenOnDesktop: {
    display: 'none',
  },
  hiddenOnMobile: {
    display: 'block',
  },
  qrCodeText: {
    marginTop: '0px',
    display: 'flex',
    justifyContent: 'center',
    width: '230px',
    float: 'right',
  },
  qrCodeTextColDesktop: {
    justifyContent: 'right',
  },
  '@media (max-width: 768px)': {
    qrcode: {
      textAlign: 'center',
      margin: '20px 0',
    },
    hiddenOnDesktop: {
      display: 'block',
    },
    hiddenOnMobile: {
      display: 'none',
    },
    qrCodeText: {
      display: 'none',
    },
  },
});

type Props = {
  classes: ClassNameMap<string>;
};

const CurrencySelection: FC<Props> = ({ classes }) => {
  const { online, payment, businessInfo } = useCheckout();

  return (
    <div>
      <OrderHeader
        businessName={businessInfo?.business_name}
        businessLogo={businessInfo?.business_logo}
        payment={payment}
      />
      <Grid className={classes.container}>
        <Row className={classes.helpContainer}>
          <Col sm={12} md={12} lg={12}>
            <Typography variant="title" className={classes.title}>
              <Translate content="messages.currency_selection.title" />
            </Typography>
            <Typography variant="body1" className={classes.instructions}>
              <Translate content="messages.currency_selection.instructions" />
              <br />
              <Translate content="messages.currency_selection.instructions_2" />
            </Typography>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} lg={6}>
            <CurrencyButton currency={'BTC'} />
          </Col>
          <Col sm={12} md={6} lg={6}>
            <CurrencyButton currency={'USDT-ERC20'} />
          </Col>
        </Row>
        {!IS_SANDBOX_MODE && (
          <Row>
            <Col sm={12} md={12} lg={12}>
              <CountdownPayment
                online={!!online}
                timeLeft={payment!.getTimeLeft()}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col sm={12}>
            <AbortLinkButton />
            <Separator />
            <Typography variant="body1">
              <Translate
                content={
                  IS_SANDBOX_MODE
                    ? 'common.contatta_esercente_sandbox'
                    : 'common.contatta_esercente'
                }
              />
            </Typography>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(CurrencySelection);
