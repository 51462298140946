import { Provider } from 'react-redux';
import { Checkout } from './components/CheckoutPage';
import { store } from './redux/store/configureStore';
import { useEffect } from 'react';
import { socketService } from './services/SocketService';

export const App = () => {
  useEffect(() => {
    !socketService.initialized && socketService.init(store);
  });

  return (
    <Provider store={store}>
      <Checkout />
    </Provider>
  );
};
