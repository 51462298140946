import moment from 'moment';

import { Decimal } from 'decimal.js';

import {
  BLOCK_EXPLORER_VIEW_TX_URL,
  ETHERSCAN_VIEW_TX_URL,
  IS_SANDBOX_MODE,
  PAYMENT_ID_QUERY_PARAM,
  PAYMENT_ORDER_RECEIPT_URL,
  SANDBOX_WALLET_BASE_URL,
} from '../config';

import { PAYMENT_ORDER_STATUS_MAPPING } from '../constants/payment-order';

import { calculateTimerExpLocal, satoshiToBtc } from '../utils/';
import { centToUSDT } from '../utils/currency_utils/USDT_ECR20_utils';
import {
  CryptoCurrency,
  PaymentOrderObject,
  RateObject,
  TransactionObject,
} from '../redux/types/types';

class Transaction {
  txid: string;
  status: string;
  created_at: Date;
  amount: Decimal | null;
  crypto_amount: Decimal | null;

  constructor(
    transaction: TransactionObject,
    rate: RateObject,
    selected_currency: CryptoCurrency | null
  ) {
    this.txid = transaction.txid;
    this.status = transaction.status;
    this.created_at = new Date(moment.utc(transaction.created_at).valueOf());
    this.crypto_amount = this.minimalUnitToCrypto(
      selected_currency,
      transaction.outs_sum
    );
    this.amount = this.crypto_amount && this.crypto_amount.times(rate.value);
  }

  getBlockExplorerLink = (crypto_currency: CryptoCurrency) => {
    if (crypto_currency === 'USDT-ERC20') {
      return `${ETHERSCAN_VIEW_TX_URL}${this.txid}`;
    }
    if (crypto_currency === 'BTC') {
      return `${BLOCK_EXPLORER_VIEW_TX_URL}${this.txid}`;
    }
  };

  minimalUnitToCrypto = (
    selected_currency: CryptoCurrency | null,
    crypto_amount: string | number
  ): Decimal | null => {
    switch (selected_currency) {
      case 'BTC':
        return satoshiToBtc(Number(crypto_amount));
      case 'USDT-ERC20':
        return centToUSDT(Number(crypto_amount));
      default:
        return null;
    }
  };
}

class PaymentOrder {
  _object: PaymentOrderObject;
  uuid: string;
  business_uuid: string;
  address: string;
  uri: string;
  _status: string;
  currency: string;
  amount: Decimal;
  received: Decimal;
  missing: Decimal;
  crypto_amount: Decimal | null;
  selected_currency: CryptoCurrency | null;
  crypto_received: Decimal | null;
  crypto_missing: Decimal | null;
  created_at: Date;
  expiry_date: Date;
  expires_in: number;
  continue_url: string | undefined;
  cancel_url: string | undefined;
  transactions: Array<Transaction>;
  reference: string;
  details: string;

  minimalUnitToCrypto = (
    selected_currency: CryptoCurrency | null,
    crypto_amount: number | string
  ): Decimal | null => {
    switch (selected_currency) {
      case 'BTC':
        return satoshiToBtc(Number(crypto_amount));
      case 'USDT-ERC20':
        return centToUSDT(Number(crypto_amount));
      default:
        return null;
    }
  };

  constructor(payment: PaymentOrderObject, business_uuid: string) {
    if (!business_uuid) {
      throw new Error('Missing business uuid');
    }
    this._object = payment;
    this.uuid = payment.uuid;
    this.business_uuid = business_uuid;
    this.address = payment.address;
    this.uri = payment.uri;
    this._status = payment.state.blockchain_status;
    this.amount = new Decimal(payment.amount);
    this.received = new Decimal(payment.state.paid.fiat).plus(
      payment.state.in_confirmation.fiat
    );
    this.missing = new Decimal(payment.state.unpaid.fiat);
    this.selected_currency = payment.selected_currency;

    this.crypto_amount = this.minimalUnitToCrypto(
      payment.selected_currency,
      payment.crypto_amount
    );
    this.crypto_received = this.minimalUnitToCrypto(
      payment.selected_currency,
      payment.state.paid.crypto + payment.state.in_confirmation.crypto
    );
    this.crypto_missing = this.minimalUnitToCrypto(
      payment.selected_currency,
      payment.state.unpaid.crypto
    );

    this.created_at = new Date(moment.utc(payment.created_at).valueOf());
    this.expiry_date = new Date(moment.utc(payment.expiration_time).valueOf());
    this.expires_in = payment.expires_in;
    this.continue_url = payment.continue_url;
    this.cancel_url = payment.cancel_url;
    this.transactions = payment.transactions.map(
      t => new Transaction(t, payment.rate, payment.selected_currency)
    );
    this.reference = payment.reference;
    this.details = payment.details;
    this.currency = payment.currency.name;
  }

  getUri = (): string => {
    if (!IS_SANDBOX_MODE) {
      return this.uri;
    } else {
      return `${SANDBOX_WALLET_BASE_URL}/${this.uuid}`;
    }
  };

  /**
   * Maps the back end payment status to the front end payment status.
   */
  getStatus = (): string => {
    return PAYMENT_ORDER_STATUS_MAPPING[this._status];
  };

  /**
   * Builds the confirmation url.
   */
  getContinueUrl = (): string | null => {
    return this.continue_url
      ? `${this.continue_url}?${PAYMENT_ID_QUERY_PARAM}=${this.uuid}`
      : null;
  };

  /**
   * Builds the cancel url.
   */
  getCancelUrl = (): string | null => {
    return this.cancel_url
      ? `${this.cancel_url}?${PAYMENT_ID_QUERY_PARAM}=${this.uuid}`
      : null;
  };

  /**
   * Returns the URL to download the payment receipt.
   */
  getReceiptLink = (): string => {
    /* /business/{b_uuid}/payment-order/po_uuid/ */
    return `${PAYMENT_ORDER_RECEIPT_URL}/business/${this.business_uuid}/payment-order/${this.uuid}/receipt`;
  };

  /**
   * Returns the number of milliseconds until the payment expiration.
   */
  getTimeLeft = (): number => {
    return calculateTimerExpLocal(this.expiry_date);
  };

  isOverpaid = (): boolean => {
    return (
      !!this.crypto_received &&
      !!this.crypto_amount &&
      this.crypto_received.greaterThan(this.crypto_amount)
    );
  };

  btcAmountOverpaid = (): number => {
    if (this.isOverpaid()) {
      return this.crypto_received!.minus(this.crypto_amount!).toNumber();
    } else {
      return 0;
    }
  };

  amountOverpaid = (): number => {
    if (this.isOverpaid()) {
      return this.received.minus(this.amount).toNumber();
    } else {
      return 0;
    }
  };

  hasReceived = (): boolean => {
    return !!this.crypto_received && this.crypto_received.greaterThan(0);
  };

  isNewerThan = (other: PaymentOrder): boolean => {
    if (IS_SANDBOX_MODE) return true;
    return (
      this.expiry_date > other.expiry_date ||
      (this.expiry_date.toString() === other.expiry_date.toString() &&
        this.expires_in < other.expires_in)
    );
  };
}

export { PaymentOrder };
