import React, { FC } from 'react';
import Translate from 'react-translate-component';
import counterpart from 'counterpart';
import { NotFoundIcon } from '../common/Icons';
import { PaymentOrderLabel, UserMessage } from '../common';
import { withStyles } from '@material-ui/core';
import { StyleRulesCallback } from '@material-ui/core/es';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { useCheckout } from '../../redux/checkout/hooks';

const styles: StyleRulesCallback = theme => ({
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
});

type Props = {
  classes: ClassNameMap;
};

const NotFound: FC<Props> = ({ classes }) => {
  const { payment } = useCheckout();

  return (
    <div style={{ marginTop: '80px' }}>
      <UserMessage
        icon={<NotFoundIcon />}
        title={<Translate content="messages.non_trovato.titolo" />}
        message={
          <React.Fragment>
            <Translate content="messages.non_trovato.parte_1" />
            <br />
            <a href={counterpart('links.contact_us')} className={classes.link}>
              <Translate content="messages.non_trovato.contatta_il_team_ChainsidePay" />
            </a>
            <Translate content="messages.non_trovato.parte_3" />
          </React.Fragment>
        }
      />
      <PaymentOrderLabel paymentId={payment!.uuid} />
    </div>
  );
};

export default withStyles(styles)(NotFound);
