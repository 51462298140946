import { createSelector } from '@reduxjs/toolkit';
import { ReduxStorage } from '../store/rootReducer';
import { CheckoutContext, CheckoutStates } from './stm/checkout.stm';
import { StateMachinesNames } from '../constants';

const getState = (state: ReduxStorage) =>
  state.checkout[StateMachinesNames.checkout].state;
const getCtx = (state: ReduxStorage) =>
  state.checkout[StateMachinesNames.checkout].context;

export const selectState = createSelector(
  getState,
  // non riesce a inferire il tipo
  (state: CheckoutStates | null) => state
);

export const selectHeaderAndFooterVisible = createSelector(
  getState,
  // non riesce a inferire il tipo
  (state: CheckoutStates | null) =>
    state !== CheckoutStates.FINAL_STATE_FAILURE &&
    state !== CheckoutStates.FINAL_STATE_SUCCESS
);

export const selectOfflineDialogIsOpen = createSelector(
  getState,
  (state: CheckoutStates | null) =>
    state === CheckoutStates.WAIT_PAYMENT ||
    state === CheckoutStates.WAIT_PAYMENT_PARTIAL
);

export const selectIsOnline = createSelector(
  getCtx,
  // non riesce a inferire il tipo
  (ctx: undefined | CheckoutContext) => ctx?.online
);

export const selectPayment = createSelector(
  getCtx,
  // non riesce a inferire il tipo
  (ctx: undefined | CheckoutContext) => ctx?.payment
);
export const selectBusinessInfo = createSelector(
  getCtx,
  // non riesce a inferire il tipo
  (ctx: undefined | CheckoutContext) => ctx?.business_info
);
