import React, { FC } from 'react';
import { OrderHeader, Loader, UserMessage } from '../common';
import Translate from 'react-translate-component';

const Searching: FC = () => {
  return (
    <div>
      <OrderHeader />
      <UserMessage
        icon={<Loader size={40} loading />}
        title={<Translate content="messages.ricerca.pagamento" />}
      />
    </div>
  );
};

export { Searching };
