import React, { FC } from 'react';

type IconProps = {
  width?: number;
  height?: number;
  className?: string;
};

type ClickableIconProps = IconProps & {
  onClick?: () => void;
};

const SuccessIcon: FC<IconProps> = ({ width = 24, height = 24, className }) => (
  <img
    src={require('./img/status/success.png').default}
    alt="Success Icon"
    width={width}
    height={height}
    className={className}
  />
);

const CancelledIcon: FC<IconProps> = ({
  width = 24,
  height = 24,
  className,
}) => (
  <img
    src={require('./img/status/cancelled.png').default}
    alt="Cancelled Icon"
    width={width}
    height={height}
    className={className}
  />
);

const NotFoundIcon: FC<IconProps> = ({
  width = 24,
  height = 24,
  className,
}) => (
  <img
    src={require('./img/status/not found.png').default}
    alt="Not Found Icon"
    width={width}
    height={height}
    className={className}
  />
);

const ExpiredIcon: FC<IconProps> = ({ width = 24, height = 24, className }) => (
  <img
    src={require('./img/status/expired.png').default}
    alt="Expired Icon"
    width={width}
    height={height}
    className={className}
  />
);

const NetworkDisputeIcon: FC<IconProps> = ({
  width = 24,
  height = 24,
  className,
}) => (
  <img
    src={require('./img/status/network_dispute.png').default}
    alt="Network Dispute Icon"
    width={width}
    height={height}
    className={className}
  />
);
const ChargebackIcon: FC<IconProps> = ({
  width = 24,
  height = 24,
  className,
}) => (
  <img
    src={require('./img/status/chargeback.png').default}
    alt="Chargeback Icon"
    width={width}
    height={height}
    className={className}
  />
);

const ExternalLinkBlueIcon = (props: ClickableIconProps) => (
  <img
    src={require('./img/general/external-link-blue.png').default}
    alt="External Link Blue Icon"
    width={props.width}
    height={props.height}
    className={props.className}
    onClick={props.onClick}
  />
);

const ExternalLinkWhiteIcon = (props: ClickableIconProps) => (
  <img
    src={require('./img/general/external-link-white.png').default}
    alt="External Link White Icon"
    width={props.width}
    height={props.height}
    className={props.className}
    onClick={props.onClick}
  />
);

const QRCodeWhiteIcon: FC<ClickableIconProps> = ({
  width = 24,
  height = 24,
  className,
  onClick,
}) => (
  <img
    src={require('./img/general/qrcode-white.png').default}
    alt="QRCode White Icon"
    width={width}
    height={height}
    className={className}
    onClick={onClick}
  />
);

const CopyContentIcon: FC<ClickableIconProps> = ({
  width = 24,
  height = 24,
  className,
  onClick,
}) => (
  <img
    src={require('./img/general/copy-content.png').default}
    alt="Copy Content Icon"
    width={width}
    height={height}
    className={className}
    onClick={onClick}
  />
);

const InformationMessageIcon: FC<IconProps> = ({
  width = 24,
  height = 24,
  className,
}) => (
  <img
    src={require('./img/general/info-circle.png').default}
    alt="Information icon"
    width={24}
    height={24}
  />
);

const HeaderLogo: FC<IconProps> = ({
  width = 116.5,
  height = 25,
  className,
}) => (
  <img
    src={require('./img/logo/CS-pay-logo-inline-grey-dark.png').default}
    alt="Header Logo"
    width={width}
    height={height}
    className={className}
  />
);

const FooterLogo: FC<IconProps> = ({
  width = 163.4,
  height = 25,
  className,
}) => (
  <img
    src={require('./img/logo/CS-pay-logo-inline-white.png').default}
    alt="Footer Logo"
    width={163.4}
    height={34}
    className={className}
  />
);

const CurrencyBTCICon: FC<IconProps> = ({
  width = 32,
  height = 32,
  className,
}) => (
  <img
    src={require('./img/currency/BTC.png').default}
    alt="BTC"
    width={width}
    height={height}
    className={className}
  />
);

const CurrencyUSDTICon: FC<IconProps> = ({
  width = 34,
  height = 34,
  className,
}) => (
  <img
    src={require('./img/currency/USDT.png').default}
    alt="USDT"
    width={width}
    height={height}
    className={className}
  />
);

export {
  SuccessIcon,
  CancelledIcon,
  NotFoundIcon,
  ExpiredIcon,
  NetworkDisputeIcon,
  ChargebackIcon,
  HeaderLogo,
  QRCodeWhiteIcon,
  ExternalLinkBlueIcon,
  ExternalLinkWhiteIcon,
  FooterLogo,
  CopyContentIcon,
  InformationMessageIcon,
  CurrencyBTCICon,
  CurrencyUSDTICon,
};
