import { Decimal } from 'decimal.js';

function centToUSDT(amount: number | Decimal): Decimal {
  return new Decimal(amount).times(new Decimal(`1e-6`));
}

function USDTToSCent(amount: Decimal): Decimal {
  return amount.times(new Decimal(`1e6`));
}

export { centToUSDT, USDTToSCent };
