import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { stateMachineStarterSaga } from 'redux-sigma';
import { stms } from '../checkout/stm';
import { rootReducer } from './rootReducer';

let onError: (error: Error) => void;

const sagaMiddleWare = createSagaMiddleware({
  onError(
    error: Error,
    errorInfo: {
      sagaStack: string;
    }
  ): void {
    // eslint-disable-next-line no-console
    console.error(error, errorInfo);
    onError(error);
  },
});
export const store = configureStore({
  reducer: rootReducer,
  middleware: [sagaMiddleWare],
  devTools: true,
});

onError = e => {
  //TODO
};

// state machines
sagaMiddleWare.run(stateMachineStarterSaga, ...stms);
